import { ActionTypes } from './types';

export const defaultState = {
  noms: {},
  dashboardClients: [],
  dashboardEmployees: [],
};

function appReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.InitAppSuccess:
      return {
        ...state,
        noms: action.payload
      };
    case ActionTypes.RequestNomencaturesSuccess:
      return {
        ...state,
        noms: action.payload
      };
    case ActionTypes.RequestDashboardClientsSuccess:
      return {
        ...state,
        dashboardClients: action.payload
      };
    case ActionTypes.SaveUserLanguage:
      return {
        ...state,
        userLanguage: action.payload
      };
    default:
      return state;
  }
}

export default appReducer;
