import { ActionTypes } from './types';

export const loginUser = () => ({
  type: ActionTypes.LoginUser
});

export const loginUserSuccess = (payload) => ({
  type: ActionTypes.LoginUserSuccess,
  payload
});

export const logoutUser = () => ({
  type: ActionTypes.LogoutUser
});

export const logoutUserSuccess = () => ({
  type: ActionTypes.LogoutUserSuccess
});

export const requestUserAccount = (id) => ({
  type: ActionTypes.RequestUserAccount,
  id
});

export const requestUserAccountSuccess = (payload) => ({
  type: ActionTypes.RequestUserAccountSuccess,
  payload
});

export const cleanUserAccount = () => ({
  type: ActionTypes.CleanUserAccount,
});

export const activateUserAccount = () => ({
  type: ActionTypes.ActivateUserAccount,
});

export const requestPasswordReset = () => ({
  type: ActionTypes.RequestPasswordReset
});

export const resetPassword = (email, token) => ({
  type: ActionTypes.ResetPassword,
  email,
  token
});

export const downloadTermsAndConditions = () => ({
  type: ActionTypes.DownloadTermsAndConditions
});

export const requestLanguageAndGender = () => ({
  type: ActionTypes.RequestLanguageAndGender
});

export const requestMyGeneralDetails = () => ({
  type: ActionTypes.RequestMyGeneralDetails
});