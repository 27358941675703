import * as React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from '.';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

const PageTitle = ({ title, breadCrumbItems, cssClass }) => {
  return (
    <Row className="mb-2">
      <Col>
        <div className="page-title-box">
          <Row>
            <div className="page-title-right breadcrumb-move">
              {breadCrumbItems && (
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/">
                      <Translate id="pages.breadcrumb.home" />
                    </Link>
                  </BreadcrumbItem>
                  {breadCrumbItems.map((item, index) => (item.active ? (
                    <BreadcrumbItem active key={index}>
                      {item.label}
                    </BreadcrumbItem>
                  ) : (
                    <BreadcrumbItem key={index}>
                      <Link to={item.path}>{item.label}</Link>
                    </BreadcrumbItem>
                  )))}
                </Breadcrumb>
              )}
            </div>
          </Row>
        <Row>
        </Row>
        {title && (
          <h4 className={`page-title ${cssClass || ''}`}>{title}</h4>
        )}
        </div>
      </Col>
    </Row>
  )
};

export default PageTitle;
