import * as React from 'react';
import Select from 'react-select';
import { FIELD_TYPES, FORM_TYPES } from './types';
import {
  Card, CardBody, Col,
  CustomInput, FormText, Input,
  Label, Row, InputGroup } from 'reactstrap';
import FormGroup from '../../components/form-group';
import LogoUploader from '../../components/logo-uploader';
import ToggleSwitch from '../../components/toggle-switch';
import { Translate, TranslateToString } from '../../components';

const renderFieldByType = (
  formName, fields, model, handleChange, mode, disable,
) => (
  fields && Object.keys(fields).map((fieldName) => {
    const field = fields[fieldName];
    const {
      children, id, name, formGroupName, subText, hidden, type, collapsable,
      data, placeholder, step, min, max, periodType, className, show, settings, info, empty, ...props
    } = field;
    const disabled = field.disabled || disable || mode === FORM_TYPES.PREVIEW;

    if (hidden || type === FIELD_TYPES.HIDDEN) {
      return null;
    }

    /* ============== RECURSIVE FIELD TYPES ============== */
    if (field.type === FIELD_TYPES.GRID) {
      return (
        <Col key={id} {...props}>
          {renderFieldByType(
            formName, children, model, handleChange, mode, disable
          )}
        </Col>
      );
    }

    if (type === FIELD_TYPES.SECTION) {
      if (!collapsable) {
        return (
          <Card key={id} {...props} className="plain-card">
            <CardBody>
              <h4 className="header-title mb-3">
                <Translate id={name} />
              </h4>
              {renderFieldByType(
                formName, children, model, handleChange, mode, disable
              )}
            </CardBody>
          </Card>
        );
      }
      return (
        <>
        </>
      );
    }

    if (type === FIELD_TYPES.FORM_GROUP) {
      return (
        <Row key={id} id={id}>
          {formGroupName && (
            <Col {...props}>
              <Label for={id}><Translate id={formGroupName} /></Label>
            </Col>
          )}
          {renderFieldByType(
            formName, children, model, handleChange, mode, disable
          )}
        </Row>
      );
    }

    /* ============== SIMPLE FIELD TYPES ============== */

    const { activeErrorState, isTouched, value } = model[id];
    const fieldIsErrored = activeErrorState.hasError && isTouched;

    if (type === FIELD_TYPES.DROPDOWN) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <Select
            className="react-select react-select-dropdown"
            classNamePrefix="react-select"
            value={value}
            isDisabled={disabled}
            options={data}
            onChange={(v) => handleChange(id, v)}
            invalid={fieldIsErrored}
            placeholder="Select option"
          />
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.LOGO) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          <LogoUploader
            logoUrl={value}
            onLogoUpload={(v) => { handleChange(id, v); }}
          />  
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.TOGGLE_SWITCH) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <div>
            <ToggleSwitch
              onChange={(v) => handleChange(id, v)}
              disabled={disabled}
              checked={value}
            />
          </div>
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.TEXT) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <InputGroup>
            <Input
              type="text"
              name="text"
              placeholder={placeholder ? TranslateToString({ id: placeholder }) : ''}
              value={value || ''}
              onChange={(e) => { handleChange(id, e.target.value); }}
              invalid={fieldIsErrored}
              disabled={disabled}
            />
          </InputGroup>
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.PASSWORD) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <InputGroup>
            <Input
              type="password"
              name="text"
              placeholder={placeholder ? TranslateToString({ id: placeholder }) : ''}
              value={value || ''}
              onChange={(e) => { handleChange(id, e.target.value); }}
              invalid={fieldIsErrored}
              disabled={disabled}
            />
          </InputGroup>
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.TEXTAREA) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <Input
            type="textarea"
            name="text"
            rows="5"
            value={value || ''}
            onChange={(e) => { handleChange(id, e.target.value); }}
            invalid={fieldIsErrored}
            disabled={disabled}
          />
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.CHECKBOX) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          <CustomInput
            id={id}
            type="checkbox"
            label={<Translate id={name} />}
            checked={value}
            onChange={(e) => handleChange(id, e.target.checked)}
            invalid={fieldIsErrored}
            disabled={disabled}
          />
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.NUMERIC) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <Input
            placeholder={placeholder}
            min={min || 0}
            max={max}
            step={step || 1}
            type="number"
            value={value || 0.0}
            onChange={(e) => handleChange(id, e.target.value || 0.0)}
            disabled={disabled}
          />
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.DATE_PICKER) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          {name && <Label for="text"><Translate id={name} /></Label>}
          <Input
            type="date"
            name={id}
            value={value || ''}
            onChange={(e) => handleChange(id, e.target.value)}
            invalid={fieldIsErrored}
            disabled={disabled}
          />
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    if (type === FIELD_TYPES.CUSTOM) {
      const { onRender } = props;
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          <Label for={id}><Translate id={name} /></Label>
          {onRender && onRender({
            ...props,
            id,
            name: id, // This should stay like this. Or look very carefully. Custom filters are relying on this
            placeholder: "Place holder",
            disabled,
            value: model[id].value,
            invalid: fieldIsErrored,
            onChange: (v) => handleChange(id, v)
          })}
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }
    

    



    if (type === FIELD_TYPES.EMAIL) {
      return (
        <FormGroup key={id} {...props} id={`${id}_input`}>
          <Label for={id}><Translate id={name} /></Label>
          <Input
            type={type}
            name={id}
            disabled={disabled}
            placeholder={placeholder ? TranslateToString({ id: placeholder }) : ''}
            value={model[id].value}
            onChange={(e) => { handleChange(id, e.target.value); }}
            invalid={fieldIsErrored}
          />
          {fieldIsErrored && (
            <FormText color="danger">
              <Translate id={activeErrorState.message} />
            </FormText>
          )}
        </FormGroup>
      );
    }

    return (
      <FormGroup key={id} {...props} id={`${id}_input`}>
        <Label for={id}><Translate id={name} /></Label>
        <Input
          type={type}
          name={id}
          disabled={disabled}
          value={model[id].value}
          onChange={(e) => { handleChange(id, e.target.value); }}
          invalid={fieldIsErrored}
          autoComplete="new-password" // Prevent browser from auto completing field
        />
        {fieldIsErrored && (
          <FormText color="danger">
            <Translate id={activeErrorState.message} />
          </FormText>
        )}
      </FormGroup>
    );
  })
);

function FieldGenerator({
  formName, fields, model,
  formState, onChange = () => null,
  disabled = false
}) {
  return (
    <>
      {renderFieldByType(
        formName, fields, model, onChange,
        formState.mode, disabled
      )}
    </>
  );
}

export default FieldGenerator;
