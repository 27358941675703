import { call, put, select, takeLatest } from 'redux-saga/effects';
import handleSagaErrors from '../../common/handle-saga-errors';
import { accountManagerForm, ActionTypes, financeDepartmentForm, IS_NEW_ID, locationContactPersonForm, mainClientFieldsForm, mainLocationFieldsForm, contactPersonForm } from './types';
import history from '../../history';
import * as Actions from './actions';
import {
  requestClients,
  requestClientDetails,
  createNewClient,
  updateClient,
  requestClientLocations,
  requestClientLocationDetails,
  createNewClientLocation,
  updateClientLocation,
  requestNomenclatures,
  requestListClientContactPersons,
  exportClientContactPersons,
  getContactPersonDetails,
  updateContactPerson
} from '../../common/api';
import { addSuccessNotification } from '../../common/notifications';
import { flattenFormModel } from '../../common/form';
import { DefaultPageParameters } from '../../constants/pagination';
import * as FormActions from '../../common/form/actions';
import { mainLocationFieldDefinitions } from './utils';
import * as InitActions from '../../layouts/actions';
import { handleFileResponse } from '../../helpers/fileUtils';

function* handleRequestClients() {
  const response = yield call(requestClients);
  yield put(Actions.requestClientsSuccess(response));
}

function* handleRequestClientDetails({ id }) {
  if (id === IS_NEW_ID) {
    return;
  }

  const response = yield call(requestClientDetails, id);
  yield put(Actions.requestClientDetailsSuccess(response));
}

function* handleSubmitClientDetails({ id }) {
  const store = yield select(s => s.Form);
  const mainForm = store[mainClientFieldsForm];
  const accountForm = store[accountManagerForm];
  const financeForm = store[financeDepartmentForm];
  
  const flatMainForm = flattenFormModel(mainForm);
  const { logoUrl, ...restMainFields } = flatMainForm;
  const flatAccountForm = flattenFormModel(accountForm);
  const flatFinanceForm = flattenFormModel(financeForm);
  
  const isNewClient = id === IS_NEW_ID;

  const formData = new FormData();
  formData.append('clientData', JSON.stringify({
    ...restMainFields,
    accountManager: flatAccountForm,
    financeDepartmentContact: flatFinanceForm
  }));

  if (logoUrl instanceof File) {
    formData.append('logo', logoUrl);
  }

  if (isNewClient) {
    const newClientResponse = yield call(createNewClient, formData);
    addSuccessNotification("New cliend created successfully");
    history.push(`/clients/${newClientResponse}`);
  } else {
    const updatedClientResponse = yield call(updateClient, id, formData);
    addSuccessNotification("Client updated successfully");
    yield put(Actions.requestClientDetailsSuccess(updatedClientResponse))
  }

  const noms = yield call(requestNomenclatures);
  yield put(InitActions.requestNomencaturesSuccess(noms))
  yield put(InitActions.requestDashboardClients());
}

function* handleRequestClientLocations({ clientId, pageParameters = DefaultPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestClientLocations, clientId, queryParamsObject);
  yield put(Actions.requestClientLocationsSuccess(response));
}

function* handleRequestClientContactPersons({ pageParameters = DefaultPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestListClientContactPersons, queryParamsObject);
  yield put(Actions.requestClientContactPersonsSuccess(response));
}

function* handleRequestClientLocationDetails({ clientId, locationId }) {
  if (locationId === IS_NEW_ID) {
    return;
  }

  const response = yield call(requestClientLocationDetails, clientId, locationId);
  yield put(Actions.requestClientLocationDetailsSuccess(response));
}

function* handleExportContactPersons() {
  const response = yield call(exportClientContactPersons);
  const { blob } = response;
  handleFileResponse(blob, 'contact-persons-export.xlsx');
}

function* handleGetContactPersonDetails({id}) {
  const response = yield call(getContactPersonDetails, id);
  yield put(Actions.requestGetContactPersonDetailsSuccess(response));
}

function* handleUpdateContactPerson({ id, callback }) {
  const store = yield select(s => s.Form);
  const updateForm = store[contactPersonForm];
  const flatForm = flattenFormModel(updateForm);
  const response = yield call(updateContactPerson, id, flatForm);
  addSuccessNotification('New contact person updated successfully');
  yield put(Actions.requestGetContactPersonDetailsSuccess(response));
  callback && callback();
}

function* handleSubmitLocationDetails({ clientId, locationId }) {
  const store = yield select(s => s);
  const { App, Form } = store;
  const mainForm = Form[mainLocationFieldsForm];
  const contactPersonForm = Form[locationContactPersonForm];
  const { noms } = App;
  
  const flatMainForm = flattenFormModel(mainForm);
  const flatContactPersonForm = flattenFormModel(contactPersonForm);

  const payload = {
    ...flatMainForm,
    contactPerson: {
      ...flatContactPersonForm
    }
  };

  const isNewLocation = locationId === IS_NEW_ID;

  if (isNewLocation) {
    const newLocationResponse = yield call(createNewClientLocation, clientId, payload);
    addSuccessNotification('New location created successfully');
    yield put(Actions.requestClientLocationDetailsSuccess(newLocationResponse));
    yield put(FormActions.updateForm(
      mainLocationFieldsForm,
      mainLocationFieldDefinitions(noms, newLocationResponse.id),
      newLocationResponse
    ));
  } else {
    const updatedLocationResponse = yield call(updateClientLocation, clientId, locationId, payload);
    addSuccessNotification('Location updated successfully');
    yield put(Actions.requestClientLocationDetailsSuccess(updatedLocationResponse))
  }

  yield put(InitActions.requestDashboardClients());
}

export default function* init() {
  yield takeLatest(ActionTypes.RequestClients, handleSagaErrors(handleRequestClients));
  yield takeLatest(ActionTypes.RequestClientDetails, handleSagaErrors(handleRequestClientDetails));
  yield takeLatest(ActionTypes.SubmitClientDetails, handleSagaErrors(handleSubmitClientDetails));
  yield takeLatest(ActionTypes.RequestClientLocations, handleSagaErrors(handleRequestClientLocations));
  yield takeLatest(ActionTypes.RequestClientLocationDetails, handleSagaErrors(handleRequestClientLocationDetails));
  yield takeLatest(ActionTypes.SubmitClientLocationDetails, handleSagaErrors(handleSubmitLocationDetails));
  yield takeLatest(ActionTypes.RequestClientContactPersons, handleSagaErrors(handleRequestClientContactPersons));
  yield takeLatest(ActionTypes.ExportContactPersons, handleSagaErrors(handleExportContactPersons));
  yield takeLatest(ActionTypes.GetContactPersonDetails, handleSagaErrors(handleGetContactPersonDetails));
  yield takeLatest(ActionTypes.UpdateContactPerson, handleSagaErrors(handleUpdateContactPerson));
};
