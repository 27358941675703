export const ActionTypes = {
  LoginUser: 'move/pages/authentication/LoginUser',
  LogoutUser: 'move/pages/authentication/logoutUser',

  LoginUserSuccess: 'move/pages/authentication/LoginUserSuccess',
  LogoutUserSuccess: 'move/pages/authentication/LogoutUser',

  RequestUserAccount: 'move/pages/authentication/RequestUserAccount',
  RequestUserAccountSuccess: 'move/pages/authentication/RequestUserAccountSuccess',
  CleanUserAccount: 'move/pages/authentication/CleanUserAccountSuccess',

  ActivateUserAccount: 'move/pages/authentication/ActivateUserAccount',

  RequestPasswordReset: 'move/pages/authentication/RequestPasswordReset',
  ResetPassword: 'move/pages/authentication/ResetPassword',

  DownloadTermsAndConditions: 'move/pages/authentication/DownloadTermsAndConditions',

  RequestLanguageAndGender: 'move/pages/authentication/RequestLanguageAndGender',

  RequestMyGeneralDetails: 'move/pages/user-profile/RequestMyGeneralDetails',
};

export const activateAccountForm = 'activateAccountForm';
export const forgetPasswordForm = 'forgetPasswordForm';
export const resetPasswordForm = 'resetPasswordForm';
export const loginForm = 'loginForm';
