import { toast } from 'react-toastify';

const baseToastOptions = (message) => ({
  position: 'bottom-center',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  toastId: message // prevents duplicate toasts
});

const errorToastOptions = (message) => ({
  ...baseToastOptions(message),
  autoClose: false
});

export const addGenericNotification = (message) => {
  toast.info(message, baseToastOptions(message));
};

export const addErrorNotification = (message) => {
  toast.error(message, errorToastOptions(message));
};

export const addSuccessNotification = (message) => {
  toast.success(message, baseToastOptions(message));
};

export const addWarningNotification = (message) => {
  toast.warn(message, errorToastOptions(message));
};
