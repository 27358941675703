import { useIntl } from 'react-intl';

const TranslateToString = ({ id, defaultMessage = null, ...values }) => {
  const intl = useIntl();
  return intl.formatMessage({
    id: id && id.length ? id : 'generic.no.translation.available',
    ...(defaultMessage ? { defaultMessage } : {}),
  }, values);
};

export default TranslateToString;
