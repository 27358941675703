import React from 'react';
import Switch from 'react-switch';

const ToggleSwitch = ({ onChange, disabled = false, checked = false }) => {
  return (
    <Switch
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      onColor="#c7dfff"
      onHandleColor="#2693e6"
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      className="react-switch"
      id="material-switch"
    />
  )
};

export default ToggleSwitch;
