import { ActionTypes } from './types';

export const defaultState = {
  userProfile: {
    generalDetails: {},
    avatar: ''
  }
};

function userProfileReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestMyGeneralDetailsSuccess:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          generalDetails: action.payload
        }
      };
    case ActionTypes.RequestMyAvatarSuccess:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          avatar: action.payload
        }
      };
    case ActionTypes.CleanProfile:
      return {
        ...state,
        userProfile: defaultState.userProfile
      };
    default:
      return state;
  }
}

export default userProfileReducer;
