export const handleFileResponse = (blob, fileName = '') => {
  const objectURL = window.URL.createObjectURL(blob);
  downloadURLObject(objectURL, fileName, true);
};

const downloadURLObject = (objectURL, name, revokeAfterwards = true) => {
  const link = document.createElement('a');
  link.href = objectURL;
  link.style.display = 'none';
  link.download = name;
  link.click();
  if (revokeAfterwards) {
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(objectURL);
    }, 100);
  }
};
