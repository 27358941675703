import { ActionTypes } from './types';

export const defaultState = {
   wearables: [],
   clients: [],
   users: [],
   wearableHistory: [],
   wearableDetails: {},
   pageParameters: {},
   historyPageParameters: {}
};

function wearablesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestWearablesSuccess: { 
      const { result, pageParameters } = action.payload;
      return {
        ...state,
        ...action.payload,
        wearables: result,
        pageParameters
      };
    }
    case ActionTypes.RequestHistorySuccess: { 
      const { result, pageParameters } = action.payload;
      return {
        ...state,
        ...action.payload,
        wearableHistory:result,
        historyPageParameters: pageParameters
      };
    }
    case ActionTypes.RequestGetWearableDetailsSuccess: { 
      return {
        ...state,
        wearableDetails: action.payload,
      };
    }
    default:
      return state;
  }
}

export default wearablesReducer;
