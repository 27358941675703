import { put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import handleSagaErrors from 'src/common/handle-saga-errors';
import { findInObjectByString } from '../../helpers/utils';
import { ActionTypes, defaultFormState } from './types';
import * as Actions from './actions';
import {
  getFormStructure,
  hasAtLeastOneErrorInState, getHashedForm
} from './utils';
import { getValidations, runValidations, getValidationDependencies } from './validations';

function* initForm({ payload }) {
  const { formName, fields, model, mode } = payload;
  const { formFields, formModel } = getFormStructure(formName, fields, model);

  const fromState = {
    ...defaultFormState,
    mode,
    errors: hasAtLeastOneErrorInState(formModel)
  };

  const initialState = getHashedForm(formModel);

  yield put(Actions.registerForm(formName, formFields, formModel, fromState, initialState));
}

function* updateForm({ payload }) {
  // TODO: For now just reinitialize the form
  yield call(initForm, { payload });
}

function* initFormField(action) {
  const store = yield select();
  const { formName, fieldName } = action.payload;

  if (formName && fieldName && store.Form[formName]) {
    const { fields, model } = store.Form[formName];
    const fieldDefinition = findInObjectByString(fields, model[fieldName].path);
    const { requestDataAction, requestActionParams, dataMapper } = fieldDefinition;
    if (requestDataAction) {
      const data = yield call(requestDataAction, requestActionParams);
      const mappedData = (dataMapper && dataMapper(data)) || data;
      yield put(Actions.changeFormFieldDataSource(formName, fieldName, mappedData));
    }
  }
}

function* changeFormField(action) {
  const store = yield select();
  const { formName, fieldName, value } = action.payload;
  const { fields, model, state, initialState } = store.Form[formName];
  const fieldDefinition = findInObjectByString(fields, model[fieldName].path);
  const validations = getValidations(fieldDefinition.validations, fieldDefinition.type);
  const runValidationsResult = runValidations(fieldName, value, validations, model);
  const newModel = {
    ...model,
    [fieldName]: {
      ...model[fieldName],
      isTouched: true,
      activeErrorState: runValidationsResult,
      value
    }
  };
  // Get validation dependencies
  const dependencies = getValidationDependencies(fieldDefinition.validations);
  if (dependencies && dependencies.length > 0) {
    dependencies.forEach(field => {
      const dependantDefinition = findInObjectByString(fields, newModel[field].path);
      const dependantValidations = getValidations(dependantDefinition.validations, dependantDefinition.type);
      const dependantValidationsResult = runValidations(field, model[field].value, dependantValidations, newModel);
      newModel[field].activeErrorState = dependantValidationsResult;
    });
  }

  yield put(Actions.updateFormField(formName, newModel));
  yield put(Actions.afterChangeFormField(formName, fieldName));

  const hashedForm = getHashedForm(newModel);

  const fromState = {
    ...state,
    pristine: hashedForm === initialState, // the from has been touched at least ones.
    errors: hasAtLeastOneErrorInState(newModel), // do we still have errors?
    submitted: false // even if the form was submitted, once we start changing fields, it should reset to a clean state
  };

  yield put(Actions.updateFormState(formName, fromState));
}

function* submitForm({ payload }) {
  const { formName, callback } = payload;
  const store = yield select();
  const { state } = store.Form[formName];

  const formState = {
    ...state,
    pristine: false, // the from has been touched at least ones.
    submitted: true
  };

  if (!state.errors) {
    // awesome! We don't have errors! Execute the action
    yield call(callback);
    // exit generator execution
    formState.pristine = true;
    yield put(Actions.updateFormState(formName, formState));
    return;
  }

  // if our form however was buggy:
  yield put(Actions.touchForms(formName));
  yield put(Actions.updateFormState(formName, formState));
}

function* submitMultipleForms({ payload }) {
  const { formNames, callback } = payload;
  const store = yield select();

  let formStates = {};
  formNames.forEach(formName => {
    const { state } = store.Form[formName];
    const formState = {
      ...state,
      pristine: false,
      submitted: true
    };
    formStates[formName] = formState;
  });

  if (!Object.keys(formStates).some(key => formStates[key].errors)) {
    // awesome! We don't have errors! Execute the action
    yield call(callback);
    // exit generator execution
    for (let index = 0; index < formNames.length; index++) {
      const currentFormName = formNames[index];
      formStates[currentFormName].pristine = true;
      yield put(Actions.updateFormState(currentFormName, formStates[currentFormName]));    
    }
    return;
  }

  for (let index = 0; index < formNames.length; index++) {
    const currentFormName = formNames[index];
    yield put(Actions.touchForms(currentFormName));
    yield put(Actions.updateFormState(currentFormName, formStates[currentFormName]));
  }
}

export default function* form() {
  yield takeLatest(ActionTypes.InitForm, handleSagaErrors(initForm));
  yield takeLatest(ActionTypes.UpdateForm, handleSagaErrors(updateForm));
  yield takeEvery(ActionTypes.InitFormField, handleSagaErrors(initFormField));
  yield takeLatest(ActionTypes.ChangeFormField, handleSagaErrors(changeFormField));
  yield takeLatest(ActionTypes.SubmitForm, handleSagaErrors(submitForm));
  yield takeLatest(ActionTypes.SubmitMultipleForms, handleSagaErrors(submitMultipleForms));
}
