import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import * as AuthActions from '../../pages/authentication/actions';
import { getLoggedInUser } from '../../helpers/authUtils';

const ProfileDropdown = ({ logoutUser, requestMyGeneralDetails, userProfile }) => {
  useEffect(() => {
    requestMyGeneralDetails();
  }, []);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const user = getLoggedInUser();
  if (!user) {
    return <></>;
  }
  const { generalDetails } = userProfile;
  const { companyPosition} = generalDetails;
  const { firstName, lastName } = user;
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle nav className="pr-0">
        <div className="user-profile-names">{firstName} {lastName}</div>
        <div className="user-profile-description">{companyPosition}</div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem tag={Link} to="/my-profile">
            Profile
          </DropdownItem>
          <DropdownItem tag={Link} onClick={() => logoutUser()}>
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  userProfile: state.UserProfile.userProfile
});

export default connect(mapStateToProps, AuthActions)(ProfileDropdown);
