import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

const PrivateRoute = ({ component: Component, roles, rights, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />;
      }

      const loggedInUser = getLoggedInUser();
      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        return <Redirect to={{ pathname: '/' }} />;
      }

      return <Component {...props} />;
    }}
  />
);
export default PrivateRoute;
