import { ActionTypes } from './types';

export const defaultState = {
  devices: [],
  clients: [],
  clientLocations: [],
  deviceHistory: [],
  deviceDetails: {},
  pageParameters: {},
  historyPageParameters: {}
};

function devicesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestDevicesSuccess: { 
      const { result, pageParameters } = action.payload;
      return {
        ...state,
        ...action.payload,
        devices:result,
        pageParameters
      };
    }
    case ActionTypes.RequestHistorySuccess: { 
      const { result, pageParameters } = action.payload;
      return {
        ...state,
        ...action.payload,
        deviceHistory:result,
        historyPageParameters: pageParameters
      };
    }
    case ActionTypes.RequestGetDeviceDetailsSuccess: { 
      return {
        ...state,
        deviceDetails: action.payload,
      };
    }
    default:
      return state;
  }
}

export default devicesReducer;
