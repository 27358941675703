import { ActionTypes } from './types';

export const defaultState = {
  roles: ['Default role 1', 'Default role 2']
};

function rolesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestRolesSuccess: 
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export default rolesReducer;
