export const USER_ROLES = {
  GLOBAL_ADMIN: 'GlobalAdmin',
  COMPANY_ADMIN: 'CompanyAdmin',
  REPORTING_USER: 'ReportingUser',
  REGULAR_USER: 'RegularUser'
};

export const USER_TYPES = {
  ADMIN: 'Admin',
  USER: 'User'
};
