import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import handleSagaErrors from '../../../../common/handle-saga-errors';
import history from '../../../../history';
import {
  ActionTypes, addNewUserForm, CLIENT_ID_FIELD,
  CLIENT_LOCATION_ID_FIELD, COMPANY_POSITION_FIELD, userAdministrationSettingsForm,
  userGeneralDetailsForm, USER_ROLE_FIELD, WEARABLE_FIELD, USER_TYPE_FIELD, ADMIN_ROLES_LIST, USER_ROLES_LIST, COMPANY_ADMIN_OPTION
} from './types';
import * as Actions from './actions';
import {
  requestListUsers,
  requestUserAvatar,
  requestUserGeneralDetails,
  updateUserAvatar,
  updateUserGeneralDetails,
  requestUserAdministrationSettings,
  updateUserAdministrationSettings,
  createNewUser,
  listDropdownClients,
  listDropdownLocations,
  exportUsers,
  importUsers,
  exportInviteTemplate,
  importInviteTemplate,
  resendUserInvitations
} from '../../../../common/api';
import { DefaultPageParameters } from '../../../../constants/pagination';
import { addSuccessNotification, addWarningNotification } from '../../../../common/notifications';
import { flattenFormModel } from '../../../../common/form';
import { ActionTypes as FormActionTypes } from '../../../../common/form/types';
import * as FormActions from '../../../../common/form/actions';
import { handleFileResponse } from '../../../../helpers/fileUtils';
import { USER_ROLES, USER_TYPES } from '../../../../constants/user-roles';
import { getLoggedInUser } from '../../../../helpers/authUtils';

function* handleRequestUsers({ pageParameters = DefaultPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestListUsers, queryParamsObject);
  yield put(Actions.requestUsersSuccess(response));
}

function* handleRequestUserGeneralDetails({ userId }) {
  const response = yield call(requestUserGeneralDetails, userId);
  yield put(Actions.requestUserGeneralDetailsSuccess(response));
}

function* handleRequestUserAvatar({ userId }) {
  const response = yield call(requestUserAvatar, userId);
  yield put(Actions.requestUserAvatarSuccess(response));
}

function* handleUploadNewAvatar({ userId, newAvatarFile }) {
  const formData = new FormData();
  formData.append('avatarFile', newAvatarFile);
  const response = yield call(updateUserAvatar, userId, formData);
  addSuccessNotification('New avatar uploaded successfully');
  yield put(Actions.requestUserAvatarSuccess(response));
}

function* handleUpdateUserGeneralDetails({ userId }) {
  const store = yield select(s => s.Form);
  const form = store[userGeneralDetailsForm];
  const flatForm = flattenFormModel(form);
  const response = yield call(updateUserGeneralDetails, userId, flatForm);
  addSuccessNotification('Profile settings updated successfully');
  yield put(Actions.requestUserGeneralDetailsSuccess(response));
}

function* handleRequestUserAdministrationSettings({ userId }) {
  const response = yield call(requestUserAdministrationSettings, userId);
  const { clientId } = response;
  if (clientId) {
    const clientLocations = yield call(listDropdownLocations, clientId);
    yield put(Actions.requestUserOptionsSuccess({ clientLocations }));
  }
  yield put(Actions.requestUserAdministrationSettingsSuccess(response));
}

function* handleUpdateUserAdministrationSettings({ userId }) {
  const store = yield select(s => s.Form);
  const adminSettingsForm = store[userAdministrationSettingsForm];
  const flatForm = flattenFormModel(adminSettingsForm);
  const response = yield call(updateUserAdministrationSettings, userId, flatForm);
  addSuccessNotification("Administration settings updated successfully");
  yield put(Actions.requestUserAdministrationSettingsSuccess(response));
  yield call(handleRequestUserGeneralDetails, { userId });
}

function* handleCreateNewUser() {
  const store = yield select(s => s.Form);
  const newUserForm = store[addNewUserForm];
  const flatForm = flattenFormModel(newUserForm);
  const response = yield call(createNewUser, flatForm);
  const { id, userExists, userEmail } = response;
  if (userExists) {
    yield put(Actions.setExistingUsersEmails([userEmail]));
  } else {
    addSuccessNotification("New user created successfully");
    history.push(`/administration/users/${id}`);
  }
}

function* handleRequestUserOptions() {
  const [clients, locations] = yield all([
    call(listDropdownClients),
    call(listDropdownLocations)
  ]);

  yield put(Actions.requestUserOptionsSuccess({ clients, locations }));
}

function* handleExportUsers() {
  const response = yield call(exportUsers);
  const { blob } = response;
  handleFileResponse(blob, 'users-export.xlsx');
}

function* handleImportUsers({ file }) {
  const formData = new FormData();
  formData.append('importFile', file);
  yield call(importUsers, formData);
  addSuccessNotification('Users imported successfully');
  yield put(Actions.requestUsers())
}

function* handleDownloadInvitationTemplate() {
  const response = yield call(exportInviteTemplate);
  const { blob } = response;
  handleFileResponse(blob, 'invitation-template.xlsx');
}

function* handleInviteUsersByTemplate({ file }) {
  const formData = new FormData();
  formData.append('inviteFile', file);
  const response = yield call(importInviteTemplate, formData);
  const { newUsersCount = 0, existingUsersCount = 0, existingUserEmails } = response;
  addSuccessNotification(`The import has been processed successfully. ${newUsersCount} invitations sent and users added to the system`);
  if (existingUsersCount > 0) {
    addWarningNotification(`${existingUsersCount} users already added to the system`);
    yield put(Actions.setExistingUsersEmails(existingUserEmails));
  }
  yield put(Actions.requestUsers())
}

function* handleResendUserInvitations({ emails, callback }) {
  yield call(resendUserInvitations, { emails });
  const message = `${emails.length > 1 ? 'Invitations' : 'Invitation'} sent successfully`
  addSuccessNotification(message);
  yield put(Actions.setExistingUsersEmails());
  history.push('/administration/users');
  callback && callback();
}

function* handleAfterChangeFormField({ payload }) {
  const { formName, fieldName } = payload;
  const state = yield select();
  const formState = state.Form[formName];
  if (!formState) {
    return;
  }
  const fieldModel = formState.model[fieldName];

  const REQUIRED_CLIENT_VALIDATION = [
    { type: 'required', message: 'generic.error.client.required' },
  ];

  const REQUIRED_LOCATION_VALIDATION = [
    { type: 'required', message: 'generic.error.location.required' },
  ];

  if (formName === addNewUserForm) {
    if (fieldName === USER_TYPE_FIELD) {
      const user = getLoggedInUser();
      const isCompanyAdmin = user.role === USER_ROLES.COMPANY_ADMIN;
      const userType = fieldModel.value.value;
      let fieldDataSource;
      if (userType === USER_TYPES.ADMIN) {
        fieldDataSource = isCompanyAdmin ? [COMPANY_ADMIN_OPTION] : ADMIN_ROLES_LIST;
      } else {
        fieldDataSource = USER_ROLES_LIST;
      }
      yield put(FormActions.changeFormFieldDataSource(formName, USER_ROLE_FIELD, fieldDataSource));
      yield put(FormActions.changeFormField(formName, USER_ROLE_FIELD, null));
      yield put(FormActions.changeFormFieldDefiniton(formName, CLIENT_LOCATION_ID_FIELD, { hidden: true }));
      yield put(FormActions.changeFormFieldDefiniton(formName, CLIENT_ID_FIELD, { hidden: true }));
      yield put(FormActions.changeFormField(formName, CLIENT_LOCATION_ID_FIELD, null));
      yield put(FormActions.changeFormField(formName, CLIENT_ID_FIELD, null));
    }

    if (fieldName === USER_ROLE_FIELD) {
      if (fieldModel.value === null) {
        return;
      }
      const userRole = fieldModel.value.value;
      const shouldHideClientOrPosition = userRole === USER_ROLES.GLOBAL_ADMIN;
      const shouldHideLocation = userRole !== USER_ROLES.REGULAR_USER;
      yield put(FormActions.changeFormFieldDefiniton(formName, COMPANY_POSITION_FIELD, { hidden: shouldHideClientOrPosition }));
      yield put(FormActions.changeFormFieldDefiniton(formName, CLIENT_ID_FIELD, { hidden: shouldHideClientOrPosition, validations: shouldHideClientOrPosition ? [] : REQUIRED_CLIENT_VALIDATION }));
      yield put(FormActions.changeFormFieldDefiniton(formName, CLIENT_LOCATION_ID_FIELD, { hidden: shouldHideLocation, validations: shouldHideLocation ? [] : REQUIRED_LOCATION_VALIDATION }));

      yield put(FormActions.changeFormField(formName, COMPANY_POSITION_FIELD, null));
      yield put(FormActions.changeFormField(formName, CLIENT_LOCATION_ID_FIELD, null));
      yield put(FormActions.changeFormField(formName, CLIENT_ID_FIELD, null));
    }

    if (fieldName === CLIENT_ID_FIELD) {
      if (fieldModel.value === null) {
        return;
      }
      const clientId = fieldModel.value.value;
      const locations = yield call(listDropdownLocations, clientId);
      yield put(FormActions.changeFormFieldDataSource(formName, CLIENT_LOCATION_ID_FIELD, locations));
      yield put(FormActions.changeFormField(formName, CLIENT_LOCATION_ID_FIELD, null));
    }
  }

  if (formName === userAdministrationSettingsForm) {
    if (fieldName === USER_ROLE_FIELD) {
      const userRole = fieldModel.value.value;
      const isNotRegularUser = userRole !== USER_ROLES.REGULAR_USER;
      const isNotCompanyAdmin = userRole !== USER_ROLES.COMPANY_ADMIN;
      yield put(FormActions.changeFormFieldDefiniton(formName, CLIENT_LOCATION_ID_FIELD, { hidden: isNotRegularUser, validations: isNotRegularUser ? [] : REQUIRED_LOCATION_VALIDATION }));
      yield put(FormActions.changeFormFieldDefiniton(formName, CLIENT_ID_FIELD, { hidden: isNotCompanyAdmin, validations: isNotCompanyAdmin ? [] : REQUIRED_CLIENT_VALIDATION }));
      yield put(FormActions.changeFormField(formName, CLIENT_LOCATION_ID_FIELD, null));
      yield put(FormActions.changeFormField(formName, CLIENT_ID_FIELD, null));
      yield put(FormActions.changeFormFieldDefiniton(formName, WEARABLE_FIELD, { hidden: isNotRegularUser }));
    }

    if (fieldName === CLIENT_ID_FIELD) {
      if (fieldModel.value === null) {
        return;
      }
      const clientId = fieldModel.value.value;
      const locations = yield call(listDropdownLocations, clientId);
      yield put(FormActions.changeFormFieldDataSource(formName, CLIENT_LOCATION_ID_FIELD, locations));
      yield put(FormActions.changeFormField(formName, CLIENT_LOCATION_ID_FIELD, null));
    }
  }
}

export default function* init() {
  yield takeLatest(ActionTypes.RequestUsers, handleSagaErrors(handleRequestUsers));
  yield takeLatest(ActionTypes.RequestUserAvatar, handleSagaErrors(handleRequestUserAvatar));
  yield takeLatest(ActionTypes.RequestUserGeneralDetails, handleSagaErrors(handleRequestUserGeneralDetails));
  yield takeLatest(ActionTypes.UploadNewAvatar, handleSagaErrors(handleUploadNewAvatar));
  yield takeLatest(ActionTypes.UpdateUserGeneralDetails, handleSagaErrors(handleUpdateUserGeneralDetails));
  yield takeLatest(ActionTypes.RequestUserAdministrationSettings, handleSagaErrors(handleRequestUserAdministrationSettings));
  yield takeLatest(ActionTypes.UpdateUserAdministrationSettings, handleSagaErrors(handleUpdateUserAdministrationSettings));
  yield takeLatest(ActionTypes.CreateNewUser, handleSagaErrors(handleCreateNewUser));
  yield takeLatest(ActionTypes.RequestUserOptions, handleSagaErrors(handleRequestUserOptions));
  yield takeLatest(ActionTypes.ExportUsers, handleSagaErrors(handleExportUsers));
  yield takeLatest(ActionTypes.ImportUsers, handleSagaErrors(handleImportUsers));
  yield takeLatest(ActionTypes.DownloadUserInvitationTemplate, handleSagaErrors(handleDownloadInvitationTemplate));
  yield takeLatest(ActionTypes.InviteUsersByTemplate, handleSagaErrors(handleInviteUsersByTemplate));
  yield takeLatest(ActionTypes.ResendUserInvitations, handleSagaErrors(handleResendUserInvitations));
  yield takeLatest(FormActionTypes.AfterChangeFormField, handleSagaErrors(handleAfterChangeFormField));
}
