export const ActionTypes = {
    RequestWearables: 'RequestWearables',
    RequestWearablesSuccess: 'RequestWearablesSuccess',
    CreateNewWearable: 'move/pages/wearables/CreateNewWearable',
    Reassign: 'move/pages/wearables/Reassign',
    UpdateWearable: 'move/pages/wearables/UpdateWearable',
    RequestHistorySuccess: 'move/pages/wearables/RequestHistorySuccess',
    RequestHistory: 'move/pages/wearables/RequestHistory',
    GetWearableDetails: 'move/pages/wearables/GetWearableDetails',
    RequestGetWearableDetailsSuccess: 'move/pages/wearables/RequestGetWearableDetailsSuccess',
    Assign: 'move/pages/wearables/Assign',
    AssignWearable: 'move/pages/wearables/AssignWearable',
  };

  export const reassignWearableForm = 'reassignWearableForm';
  export const assignWearableForm = 'assignWearableForm';
  export const updateWearableForm = 'updateWearableForm';
  export const wearableForm = 'wearableForm';

  export const CLIENT_ID_FIELD = 'clientId';
  export const USER_ID_FIELD = 'userId';
  