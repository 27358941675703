import { ActionTypes } from './types';

export const defaultState = {
  users: [],
  userProfile: {
    userAvatar: '',
    userGeneralDetails: {},
    userAdministrationSettings: {}
  },
  clients: [],
  clientLocations: [],
  existingUsersEmails: []
};

function usersReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestUsersSuccess: { 
      const { result, pageParameters } = action.payload;
      return {
        ...state,
        users: result,
        pageParameters
      };
    }
    case ActionTypes.RequestUserAvatarSuccess:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          userAvatar: action.payload
        }
      };
    case ActionTypes.RequestUserGeneralDetailsSuccess:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          userGeneralDetails: action.payload
        }
      };
    case ActionTypes.RequestUserAdministrationSettingsSuccess:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          userAdministrationSettings: action.payload
        }
      };
    case ActionTypes.CleanUserProfile:
      return {
        ...state,
        userProfile: defaultState.userProfile
      };
    case ActionTypes.RequestUserOptionsSuccess:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.SetExistingUsersEmails:
      return {
        ...state,
        existingUsersEmails: action.payload
      };
    default:
      return state;
  }
}

export default usersReducer;
