export const ActionTypes = {
  RequestClients: 'move/pages/clients/RequestClients',
  RequestClientsSuccess: 'move/pages/clients/RequestClientsSuccess',
  RequestClientDetails: 'move/pages/clients/RequestClientDetails',
  RequestClientDetailsSuccess: 'move/pages/clients/RequestClientDetailsSuccess',
  SubmitClientDetails: 'move/pages/clients/SubmitClientDetails',
  CleanClientDetails: 'move/pages/clients/CleanClientDetails',
  RequestClientLocations: 'move/pages/clients/RequestClientLocations',
  RequestClientLocationsSuccess: 'move/pages/clients/RequestClientLocationsSucces',
  SubmitClientLocationDetails: 'move/pages/clients/SubmitClientLocationDetails',
  RequestClientLocationDetails: 'move/pages/clients/RequestClientLocationDetails',
  RequestClientLocationDetailsSuccess: 'move/pages/clients/RequestClientLocationDetailsSuccess',
  CleanClientLocationDetails: 'move/pages/clients/CleanClientLocationDetails',
  RequestClientContactPersons: 'move/pages/clients/RequestClientContactPersons',
  RequestClientContactPersonsSuccess: 'move/pages/clients/RequestClientContactPersonsSuccess',
  ExportContactPersons: 'move/pages/clients/ExportContactPersons',
  GetContactPersonDetails: 'move/pages/clients/GetContactPersonDetails',
  RequestGetContactPersonDetailsSuccess: 'move/pages/clients/RequestGetContactPersonDetailsSuccess',
  UpdateContactPerson: 'move/pages/clients/UpdateContactPerson'
};

export const mainClientFieldsForm = 'mainClientFieldsForm';
export const accountManagerForm = 'accountManagerForm';
export const financeDepartmentForm = 'financeDepartmentForm';
export const contactPersonForm = 'contactPersonForm';

export const mainLocationFieldsForm = "mainLocationFieldsForm";
export const locationContactPersonForm = "locationContactPersonForm";

export const IS_NEW_ID = 'isNew';
