import { call, put, takeLatest, select } from 'redux-saga/effects';
import history from '../../../history';
import handleSagaErrors from '../../../common/handle-saga-errors';
import { ActionTypes, reassignWearableForm, assignWearableForm, wearableForm, updateWearableForm, CLIENT_ID_FIELD, USER_ID_FIELD } from './types';
import * as Actions from './actions';
import { addSuccessNotification } from '../../../common/notifications';
import { 
  createNewWearable,
  requestListWearables,
  reassignWearable,
  requestListWearableHistory,
  updateWearable,
  listDropdownUsers,
  getWearableDetails,
  assignWearable
 } from '../../../common/api';
import { DefaultPageParameters, WearableHistoryPageParameters } from '../../../constants/pagination';
import { flattenFormModel } from '../../../common/form';
import { ActionTypes as FormActionTypes } from '../../../common/form/types';
import * as FormActions from '../../../common/form/actions';

function* handleRequestWearables({ pageParameters = DefaultPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestListWearables, queryParamsObject);
  yield put(Actions.requestWearablesSuccess(response));
}

function* handleCreateNewWearable() {
  const store = yield select(s => s.Form);
  const newWearableForm = store[wearableForm];
  const flatForm = flattenFormModel(newWearableForm);
  const response = yield call(createNewWearable, flatForm);
  addSuccessNotification("New wearable created successfully");
  history.push(`/administration/wearables`);
}

function* handleUpdateWearable({ id, callback }) {
  const store = yield select(s => s.Form);
  const updateForm = store[updateWearableForm];
  const flatForm = flattenFormModel(updateForm);
  const response = yield call(updateWearable, id, flatForm);
  addSuccessNotification('New wearable updated successfully');
  yield put(Actions.requestGetWearableDetailsSuccess(response));
  callback && callback();
}

function* handleReassign({ callback, id }) {
  const store = yield select(s => s.Form);
  const reassignForm = store[reassignWearableForm];
  const flatForm = flattenFormModel(reassignForm);
  const body = {
    id,
    ...flatForm
  };
  yield call(reassignWearable, body);
  addSuccessNotification("Reassign successfully");
  yield put(Actions.requestHistory(id));
  callback && callback();
}

function* handleAssign({ callback, id }) {
  const store = yield select(s => s.Form);
  const assignForm = store[assignWearableForm];
  const flatForm = flattenFormModel(assignForm);
  const body = {
    id,
    ...flatForm
  };
  yield call(assignWearable, body);
  addSuccessNotification("Assign successfully");
  callback && callback();
}

function* handleRequestHistory({ id, pageParameters = WearableHistoryPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestListWearableHistory, id, queryParamsObject);
  yield put(Actions.requestHistorySuccess(response));
}

function* handleGetWearableDetails({id}) {
  const response = yield call(getWearableDetails, id);
  yield put(Actions.requestGetWearableDetailsSuccess(response));
}

function* handleAfterChangeFormField({ payload }) {
  const { formName, fieldName } = payload;
  const state = yield select();
  const formState = state.Form[formName];
  if (!formState) {
    return;
  }
  const fieldModel = formState.model[fieldName];

  if (formName === assignWearableForm || formName === reassignWearableForm) {
    if (fieldName === CLIENT_ID_FIELD) {
      const clientId = fieldModel.value.value;
      const users = yield call(listDropdownUsers, clientId);
      yield put(FormActions.changeFormFieldDefiniton(formName, USER_ID_FIELD, { hidden: clientId === null }));
      yield put(FormActions.changeFormFieldDataSource(formName, USER_ID_FIELD, users));
      yield put(FormActions.changeFormField(formName, USER_ID_FIELD, null));
    }
  }
}

export default function* init() {
   yield takeLatest(ActionTypes.RequestWearables, handleSagaErrors(handleRequestWearables));
   yield takeLatest(ActionTypes.CreateNewWearable, handleSagaErrors(handleCreateNewWearable));
   yield takeLatest(ActionTypes.RequestHistory, handleSagaErrors(handleRequestHistory));
   yield takeLatest(ActionTypes.Reassign, handleSagaErrors(handleReassign));
   yield takeLatest(ActionTypes.UpdateWearable, handleSagaErrors(handleUpdateWearable));
   yield takeLatest(ActionTypes.GetWearableDetails, handleSagaErrors(handleGetWearableDetails));
   yield takeLatest(ActionTypes.Assign, handleSagaErrors(handleAssign));
   yield takeLatest(FormActionTypes.AfterChangeFormField, handleSagaErrors(handleAfterChangeFormField));
}
