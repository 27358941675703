import { ActionTypes } from './types';

export const requestExtendedNomenclatures = () => ({
  type: ActionTypes.RequestExtendedNomenclatures
});

export const requestExtendedNomenclaturesSuccess = (payload) => ({
  type: ActionTypes.RequestExtendedNomenclaturesSuccess,
  payload
});

export const cleanExtendedNomenclatures = () => ({
  type: ActionTypes.CleanExtendedNomenclatures
});

export const setEditNomModel = (nomObject) => ({
  type: ActionTypes.SetEditNomModel,
  nomObject
});

export const updateNomenclature = (nomType, callback) => ({
  type: ActionTypes.UpdateNomenclature,
  nomType,
  callback
})
