import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import './helpers/initFA';

ReactDOM.render(
  <Main>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </Main>,
  document.getElementById('main')
);
