import { ActionTypes } from './types';

export const defaultState = {
  clients: [],
  clientDetails: {},
  clientLocations: {
    locations: [],
    pageParameters: {}
  },
  clientLocationDetails: {},
  contactPersons: {
    persons: [],
    pageParameters: {}
  },
  contactPersonDetails: {},
};

function clientsReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestClientsSuccess:
      return {
        ...state,
        clients: action.payload
      };
    case ActionTypes.RequestClientDetailsSuccess:
      return {
        ...state,
        clientDetails: action.payload
      };
    case ActionTypes.CleanClientDetails:
      return {
        ...state,
        clientDetails: defaultState.clientDetails
      };
    case ActionTypes.RequestClientLocationsSuccess: {
      const { result, pageParameters } = action.payload;
      return {
        ...state,
        clientLocations: {
          ...state.clientLocations,
          locations: result,
          pageParameters
        }
      }
    }
    case ActionTypes.RequestClientLocationDetailsSuccess:
      return {
        ...state,
        clientLocationDetails: action.payload
      };
    case ActionTypes.CleanClientLocationDetails:
      return {
        ...state,
        clientLocationDetails: defaultState.clientLocationDetails
      };
    case ActionTypes.RequestClientContactPersonsSuccess: {
     const { result, pageParameters } = action.payload;
      return {
        ...state,
        contactPersons: {
          ...state.contactPersons,
          pageParameters,
          persons: result
          }
        }
      }
    case ActionTypes.RequestGetContactPersonDetailsSuccess: { 
      return {
        ...state,
        contactPersonDetails: action.payload,
      }
    }    
    default:
      return state;
  }
}

export default clientsReducer;
