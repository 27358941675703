import { call, put, takeLatest } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import handleSagaErrors from '../common/handle-saga-errors';
import { ActionTypes, DEFAULT_LANG_ID } from './types';
import * as Actions from './actions';
import {
  requestNomenclatures,
  requestDashboardClients
} from '../common/api';
import { getLoggedInUser } from '../helpers/authUtils';
import { LANGUAGE_COOKIE_NAME } from '../constants/cookie-names';
import { getLangId, setNewLanguageCookie } from '../helpers/langUtils';

function* handleInitApp({ }) {
  const noms = yield call(requestNomenclatures);
  yield put(Actions.initAppSuccess(noms));

  const user = getLoggedInUser();
  if (!user) {
    return;
  }

  yield put(Actions.requestDashboardClients());

  const langId = getLangId();
  let language = noms.languages.find(x => x.value === langId);
  if (!language) {
    language = noms.languages.find(x => x.value === DEFAULT_LANG_ID);
  }

  setNewLanguageCookie(language);
  yield put(Actions.saveUserLanguage(language));
}

function* handleRequestDashboardClients() {
  const dashboardClients = yield call(requestDashboardClients);
  yield put(Actions.requestDashboardClientsSuccess(dashboardClients));
}

export default function* init() {
  yield takeLatest(ActionTypes.InitApp, handleSagaErrors(handleInitApp));
  yield takeLatest(ActionTypes.RequestDashboardClients, handleSagaErrors(handleRequestDashboardClients));
}
