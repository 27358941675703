export const ActionTypes = {
  RequestExtendedNomenclatures: 'move/pages/administration/nomenclatures/RequestExtendedNomenclatures',
  RequestExtendedNomenclaturesSuccess: 'move/pages/administration/nomenclatures/RequestExtendedNomenclaturesSuccess',
  CleanExtendedNomenclatures: 'move/pages/administration/nomenclatures/CleanExtendedNomenclatures',
  SetEditNomModel: 'move/pages/administration/nomenclatures/SetEditNomModel',
  UpdateNomenclature: 'move/pages/administration/nomenclatures/UpdateNomenclature'
};

export const DEFAULT_NOM_MODEL = {
  value: null,
  label: ''
};

export const LANGUAGE_NOM_MODEL = {
  value: null,
  label: '',
  code: ''
};

export const EXTENDED_NOMS_KEYS = {
  LANGUAGES: 'languages',
  GENDER: 'gender',
  COUNTRIES: 'countries',
  USER_ROLE: 'userRole',
  USER_TYPE: 'userType'
};
