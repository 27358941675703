export const ActionTypes = {
  RequestDashboardEmployeeOptions: 'move/pages/main/RequestDashboardEmployeeOptions',
  RequestDashboardEmployeeOptionsSuccess: 'move/pages/main/RequestDashboardEmployeeOptionsSuccess',
  RequestChartData: 'move/pages/main/RequestChartData',
  RequestChartDataSuccess: 'move/pages/main/RequestChartDataSuccess',
  ExportDashboard: 'move/pages/main/ExportDashboard'
};

export const DASHBOARDS = {
  HEART_RATE: 'heartRate',
  STEPS: 'steps',
  DISTANCE: 'distance',
  CALORIES: 'calories'
};

export const DASHBOARD_METRIC_TYPE = {
  HEART_RATE: 0,
  STEPS: 1,
  DISTANCE: 2,
  CALORIES: 3
};

export const WIDGETS = {
  LATEST_HEART_RATE: 'latestHeartRate',
  AVG_HEART_RATE_TODAY: 'avgHeartRateToday',
  AVG_HEART_RATE_THIS_WEEK: 'avgHeartRateThisWeek',
  TOTAL_STEPS: 'totalSteps',
  AVG_STEPS_TODAY: 'avgStepsToday',
  AVG_STEPS_THIS_MONTH: 'avgStepsThisMonth',
  AVG_DISTANCE_TODAY: 'avgDistanceToday',
  AVG_DISTANCE_THIS_MONTH: 'avgDistanceThisMonth',
  MONTHLY_HEART_RATE_OVER_TIME: 'monthlyHeartRateOverTimePerPeriod',
  MONTHLY_HEART_RATE_OVER_TIME_PER_GENDER: 'monthlyHeartRateOverTimePerGender',
  MONTHLY_DISTANCE_OVER_TIME_PER_GENDER: 'monthlyDistanceOverTimePerGender',
  MONTHLY_STEPS_OVER_TIME_PER_GENDER: 'monthlyStepsOverTimePerGender',
  DAILY_HEART_RATE_OVER_TIME: 'dailyHeartRateOverTimePerPeriod',
  MONTHLY_HEART_RATE_OVER_TIME_PER_AGE_GROUP: 'monthlyHeartRateOverTimePerAgeGroup',
  MONTHLY_DISTANCE_OVER_TIME_PER_AGE_GROUP: 'monthlyDistanceOverTimePerAgeGroup',
  MONTHLY_STEPS_OVER_TIME_PER_AGE_GROUP: 'monthlyStepsOverTimePerAgeGroup',
  MONTHLY_DISTANCE_COMPARED_TO_GOAL: 'monthlyDistanceComparedToGoal',
  MONTHLY_STEPS_COMPARED_TO_GOAL: 'monthlyStepsComparedToGoal',
  BAR_MONTHLY_STEPS_COMPARED_TO_GOAL: 'barMonthlyStepsComparedToGoal',
  BAR_MONTHLY_DISTANCE_COMPARED_TO_GOAL: 'barMonthlyDistanceComparedToGoal',
  BAR_DAILY_STEPS_COMPARED_TO_GOAL: 'barDailyStepsComparedToGoal',
  BAR_DAILY_DISTANCE_COMPARED_TO_GOAL: 'barDailyDistanceComparedToGoal',
  NUMBER_EMPLOYEES_WITH_ELEVATED_HEART_RATE_TODAY: 'numberOfEmployeesWithElevatedHeartRateToday',
  NUMBER_EMPLOYEES_WITH_ELEVATED_HEART_RATE_THIS_MONTH: 'numberOfEmployeesWithElevatedHeartRateThisMonth',
  DAILY_HEART_RATE_PER_INTENSITY_RANGE: 'dailyHeartRatePerIntensityRange',
  ACHIEVEMENT: 'achievement',
  BAR_MONTHLY_CALORIES_OVER_TIME: 'barMonthlyCaloriesOverTime',
  AVG_CALORIES_TODAY: 'avgCaloriesToday',
  AVG_CALORIES_THIS_MONTH: 'avgCaloriesThisMonth',
  AVG_CALORIES_THIS_WEEK: 'avgCaloriesThisWeek',
};

export const WIDGET_UNITS = {
  BPM: 'bpm',
  STEPS: 'steps',
  METERS: 'meters',
  KCAL: 'kcal',
  KM: 'km',
  PERCENTAGE: '%'
};

export const chartSeriesMarkerCongif = {
  marker: {
    symbol: 'circle',
  }
};

export const baseChartConfig = {
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
};

export const HIGHCHARTS_COLORS = {
  MAIN_SERIES: '#58afe8',
  PREVIOUS_PERIOD_SERIES: '#000000',
  MALE_SERIES: '#58afe8',
  FEMALE_SERIES: '#000000',
  COMPARISON_COLUMNS_SERIES: '#c9c9c9'
};

export const DEFAULT_HIGHCHARTS_COLORS = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];

export const HEART_RATE_CONSTANTS = {
  MAX_BPM: 220
};

export const SINGLE_DAY_TICK_INTERVAL = 24 * 3600 * 1000;
