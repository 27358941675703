import { put, takeEvery } from 'redux-saga/effects';
import handleSagaErrors from '../../../../common/handle-saga-errors';
import { ActionTypes } from './types';
import * as Actions from './actions';

function* handleRequestRoles({ }) {
  const response = {
    roles: ['Role 1', 'Role 2']
  };
  yield put(Actions.requestRolesSuccess(response));
}

export default function* init() {
  yield takeEvery(ActionTypes.RequestRoles, handleSagaErrors(handleRequestRoles));
}
