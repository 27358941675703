import { FIELD_TYPES } from './types';

export const required = (message = 'This field is required', type) => (value) => {
  let hasError = !value;
  if (type === FIELD_TYPES.TEXT) {
    hasError = !value;
  }

  if (type === FIELD_TYPES.DROPDOWN) {
    hasError = !value || value.length === 0;
  }

  return {
    hasError,
    message: hasError ? message : null
  };
};

export const matchEmail = (message = 'This is not a valid email') => (text) => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const hasError = text ? pattern.test(String(text).toLowerCase()) === false : false;

  return {
    hasError,
    message: hasError ? message : null
  };
};

export const maxLength = (message = `Field input must be below ${length} characters`, type, length) => (value) => {
  if (!value) {
    return {
      hasError: false,
      message: null
    };
  }

  let hasError = value.length > length;;

  return {
    hasError,
    message: hasError ? message : null,
    messageParams: { length }
  };
};

export const rangeValidation =
  (type, range, message = `Field input must be between ${range.min} and ${range.max}`) => (value) => {
    if (!value) {
      return {
        hasError: false,
        message: null
      };
    }

    const hasError = value > range.max || value < range.min;
    return {
      hasError,
      message: hasError ? message : null,
      messageParams: { range, min: range.min }
    };
  };

export const phoneNumber = (message = 'Phone number is not valid') => (value) => {
  if (!value) {
    return {
      hasError: false
    };
  }

  const passwordPattern = '^[0-9]*$';
  const passwordRegex = new RegExp(passwordPattern);
  const hasError = !passwordRegex.test(value);
  return {
    hasError,
    message: hasError ? message : null
  };
};

export const getValidationDependencies = (validations) => (
  validations ?
    validations.reduce((acc, cur) => (cur.dependencies ? acc.concat(cur.dependencies) : acc), []) :
    []
);

export const getValidations = (constraints = [], type) => {
  if (!constraints) {
    return [];
  }

  return constraints.map((c) => {
    switch (c.type) {
      case 'required':
        return required(c.message, type);
      case 'maxLength':
        return maxLength(c.message, type, c.length);
      case 'range':
        return rangeValidation(type, c.range, c.message);
      case 'email':
        return matchEmail(c.message);
      case 'phoneNumber':
        return phoneNumber(c.message);
      case 'custom':
        return c.validate;
      default:
        return () => ({
          hasError: false
        });
    }
  });
};

export const runValidations = (fieldName, text, validations, model) => {
  const firstValidationError = validations
    .map((validation) => validation(text, model))
    .find((validation) => validation.hasError);

  return firstValidationError || {
    hasError: false,
    message: ''
  };
};
