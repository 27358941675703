import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import history from './history';
import * as Actions from './layouts/actions';
import { messages } from './i18n';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { isUserAuthenticated } from './helpers/authUtils';
import { getLangCode } from './helpers/langUtils';

const App = ({ initApp }) => {
  useEffect(() => {
    if (isUserAuthenticated())
    {
      initApp();
    }
  }, []);

  const languageCode = getLangCode();
  const languageAbbrt = languageCode && languageCode in messages ? languageCode : 'EN';

  return (
    <IntlProvider locale={languageAbbrt.toLowerCase()} messages={messages[languageAbbrt] || {}}>
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  language: state.App.userLanguage
});

export default connect(mapStateToProps, Actions)(App);
