import { request } from './api';
import * as QS from 'qs';

export const requestChartData = async (chart, body) => request(`api/dashboards/${chart}`, 'POST', body);
export const exportDashboard = async (queryParamsObject) => {
  const mainPath = `api/dashboards/exportDashboard`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
