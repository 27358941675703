const en = require('./en.json');
const bg = require('./bg.json');
const es = require('./es.json');
const cns = require('./zh-cn.json');
const cnt = require('./zh-tw.json');
const jp = require('./jp.json');
const ko = require('./ko.json');
const it = require('./it.json');

export const messages = {
  EN: en,
  BG: bg,
  ES: es,
  JP: jp,
  KR: ko,
  IT: it,
  'zh-CN': cns,
  'zh-TW': cnt
};
