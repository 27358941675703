import { ActionTypes } from './types';

export const requestDashboardEmployeeOptions = (clientId, locationId) => ({
  type: ActionTypes.RequestDashboardEmployeeOptions,
  clientId,
  locationId
});

export const requestDashboardEmployeeOptionsSuccess = (payload) => ({
  type: ActionTypes.RequestDashboardEmployeeOptionsSuccess,
  payload
});

export const requestChartData = (dashboard, chart, filters) => ({
  type: ActionTypes.RequestChartData,
  dashboard,
  chart,
  filters
});

export const requestChartDataSuccess = (dashboard, chart, payload) => ({
  type: ActionTypes.RequestChartDataSuccess,
  dashboard,
  chart,
  payload
});

export const exportDashboard = (dashboard, clientId, locationId) => ({
  type: ActionTypes.ExportDashboard,
  dashboard,
  clientId,
  locationId
});
