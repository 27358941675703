import { ActionTypes } from './types';

export const defaultState = {
  userAccount: {}
};

function authenticationReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.LoginUserSuccess: 
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.RequestUserAccountSuccess: 
      return {
        ...state,
        userAccount: action.payload
      };
    case ActionTypes.CleanUserAccount: 
      return {
        ...state,
        userAccount: defaultState.userAccount
      };
    case ActionTypes.CleanUserAccount: 
      return {
        ...state,
        userAccount: defaultState.userAccount
      };
    case ActionTypes.LogoutUserSuccess: 
      return defaultState;
    default:
      return state;
  }
}

export default authenticationReducer;
