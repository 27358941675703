export const DefaultPageParameters = {
  pageNumber: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  orderBy: 'Id desc',
  hasNext: false,
  hasPrevious: false
};

export const DeviceHistoryPageParameters = {
  pageNumber: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  orderBy: 'StartDate desc',
  hasNext: false,
  hasPrevious: false
};

export const WearableHistoryPageParameters = {
  pageNumber: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 0,
  orderBy: 'StartDate desc',
  hasNext: false,
  hasPrevious: false
};