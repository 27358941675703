import { ActionTypes  } from './types';

export const requestWearables = (pageParameters) => ({
  type: ActionTypes.RequestWearables,
  pageParameters
});

export const requestWearablesSuccess = (payload) => ({
  type: ActionTypes.RequestWearablesSuccess,
  payload
});

export const createNewWearable = () => ({
  type: ActionTypes.CreateNewWearable
});

export const updateWearable = (id, callback) => ({
  type: ActionTypes.UpdateWearable,
  id,
  callback
});

export const reassign = (callback, id) => ({
  type: ActionTypes.Reassign,
  id,
  callback
});

export const assign = (callback, id) => ({
  type: ActionTypes.Assign,
  id,
  callback
});

export const requestHistory = (id, pageParameters) => ({
  type: ActionTypes.RequestHistory,
  id,
  pageParameters
});

export const requestHistorySuccess = (payload) => ({
  type: ActionTypes.RequestHistorySuccess,
  payload
});

export const getWearableDetails = (id) => ({
  type: ActionTypes.GetWearableDetails,
  id
});

export const requestGetWearableDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestGetWearableDetailsSuccess,
  payload
});


