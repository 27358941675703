import { findInObjectByString } from '../../helpers/utils';
import * as hasher from 'object-hash';
import { getValidations, runValidations } from './validations';
import { FIELD_TYPES } from './types';
import { getDateFromUnixObject } from '../../helpers/utils';
import { formatFormDate } from '../../helpers/utils';

export const createFormField = (field) => {
  if (isComplexField(field.type)) {
    return {
      [field.id]: {
        ...field,
        children: field.children && field.children.reduce((acc, cur) => ({
          ...acc,
          ...createFormField(cur)
        }), {})
      }
    };
  }

  return ({
    [field.id]: {
      ...field
    }
  });
};

export const createFormFieldModel = (field, model, path = '') => {
  const fieldName = field.id;
  let fieldValue = model && model[fieldName];
  if (fieldValue !== undefined && field.type === FIELD_TYPES.DROPDOWN) {
    if (field.data) {
      fieldValue = field.data.find((i) => i.value === fieldValue);
    } 
  }

  if (field.type === FIELD_TYPES.DATE_PICKER) {
    fieldValue = (fieldValue ? formatFormDate(getDateFromUnixObject(fieldValue)) : null);
  }

  if (isComplexField(field.type)) {
    return field.children && Object.keys(field.children)
      .reduce((acc, cur) => ({
        ...acc,
        ...createFormFieldModel(field.children[cur], model, `${path}${path ? '.' : ''}${fieldName}.children`)
      }),
      {});
  }

  return {
    [fieldName]: {
      path: `${path}.${fieldName}`,
      value: fieldValue,
      // we don't care if the field is touched when it is hidden
      ...(field.hidden ? {} : { isTouched: false }),
      // We run the validations later on
    }
  };
};

export const isComplexField = (type) => (
  type === FIELD_TYPES.SECTION || type === FIELD_TYPES.GRID || type === FIELD_TYPES.FORM_GROUP
);

export const isSurveyField = (type) => (
  type === FIELD_TYPES.SURVEY_SINGLE_SELECT ||
  type === FIELD_TYPES.SURVEY_MULTI_SELECT ||
  type === FIELD_TYPES.SURVEY_MARIX ||
  type === FIELD_TYPES.SURVEY_FILE_UPLOAD ||
  type === FIELD_TYPES.SURVEY_VERBATUM
);

export const getFormStructure = (formName, fields, model) => {
  if (!fields) {
    return {
      formFields: {},
      formModel: {}
    };
  }

  const formFields = fields.reduce((acc, curr) => ({
    ...acc,
    ...createFormField(curr, model, formName)
  }), {});

  const formModel = Object.keys(formFields)
    .reduce((acc, curr) => ({
      ...acc,
      ...createFormFieldModel(formFields[curr], model)
    }), {});

  // Run the validations
  const formModelWithValidations = Object.keys(formModel).reduce((acc, fieldName) => {
    const fieldModel = acc[fieldName];
    const fieldDefinition = findInObjectByString(formFields, acc[fieldName].path);
    const validations = getValidations(fieldDefinition.validations, fieldDefinition.type);
    const runValidationsResult = runValidations(fieldName, fieldModel.value, validations, acc);
    return {
      ...acc,
      [fieldName]: {
        ...acc[fieldName],
        activeErrorState: runValidationsResult
      }
    };
  }, formModel);

  return {
    formFields,
    formModel: formModelWithValidations
  };
};

const touchAllFields = (model) => Object.keys(model).reduce((acc, cur) => {
  if (!model[cur].isTouched) {
    return {
      ...acc,
      [cur]: {
        ...model[cur],
        isTouched: true
      }
    };
  }
  return {
    ...acc,
    [cur]: model[cur]
  };
}, {});

export const touchAllFormFields = (form) => {
  const { model, state } = form;
  return touchAllFields(form.model);
};

export const hasAtLeastOneErrorInState = (model, conditions = null) => Object.keys(model)
  .some(
    (key) => {
      const field = model[key];
      const condition = conditions && conditions[key];
      if (condition) {
        return condition.result && field.activeErrorState.hasError;
      }
      return field.activeErrorState.hasError;
    }
  );

export const flattenFormModel = (formObject, touchedFields = false, type = null, conditions = null) => {
  const { fields, model } = formObject;
  return Object.keys(model).reduce((acc, cur) => {
    const curModel = model[cur];
    if (touchedFields && !curModel.isTouched) {
      return acc;
    }

    let currentValue = curModel.value;
    const fieldDefinition = findInObjectByString(fields, curModel.path);
    if ((fieldDefinition.type === FIELD_TYPES.TEXT
          || fieldDefinition.type === FIELD_TYPES.TEXTAREA)
          && currentValue) {
      currentValue = currentValue.toString().trim();
    }
    if (type && fieldDefinition.type !== type) {
      return acc;
    }
    if (fieldDefinition.type === FIELD_TYPES.DROPDOWN && currentValue) {
      currentValue = currentValue.value;
    }

    return {
      ...acc,
      [cur]: currentValue
    };
  }, {});
};

export const getHashedForm = (form) => {
  const formValues = {};
  const keys = Object.keys(form);
  keys.forEach(key => {
    formValues[key] = form[key].value;
  });
  const hashedForm = hasher.MD5(JSON.stringify(formValues));
  return hashedForm;
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
