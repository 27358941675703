import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as Actions from './actions';
import { ActionTypes, DASHBOARDS, DASHBOARD_METRIC_TYPE } from './types';
import { getLoggedInUser } from '../../helpers/authUtils';
import { requestEmployeeOptions } from '../../common/api';
import handleSagaErrors from '../../common/handle-saga-errors';
import { USER_ROLES } from '../../constants/user-roles';
import { requestChartData, exportDashboard } from '../../common/api-dashboard';
import { handleFileResponse } from '../../helpers/fileUtils';

function* handleRequestDashboardEmployeeOptions({ clientId, locationId }) {
  const user = getLoggedInUser();
  if (user.role !== USER_ROLES.REGULAR_USER) {
    const employeeList = yield call(requestEmployeeOptions, clientId, locationId);
    yield put(Actions.requestDashboardEmployeeOptionsSuccess(employeeList));
  }
}

function* handleRequestChartData({ dashboard, chart, filters }) {
  const response = yield call(requestChartData, chart, filters);
  yield put(Actions.requestChartDataSuccess(dashboard, chart, response));
}

function* handleExportDashboard({ dashboard, clientId, locationId }) {
  let dashboardType = null;
  if (dashboard === DASHBOARDS.HEART_RATE) dashboardType = DASHBOARD_METRIC_TYPE.HEART_RATE;
  if (dashboard === DASHBOARDS.STEPS) dashboardType = DASHBOARD_METRIC_TYPE.STEPS;
  if (dashboard === DASHBOARDS.DISTANCE) dashboardType = DASHBOARD_METRIC_TYPE.DISTANCE;
  if (dashboard === DASHBOARDS.CALORIES) dashboardType = DASHBOARD_METRIC_TYPE.CALORIES;
  const queryParamsObject = {
    dashboardType,
    clientId,
    locationId
  };
  const response = yield call(exportDashboard, queryParamsObject);
  const { blob } = response;
  handleFileResponse(blob, 'dashboard-export.xlsx');
}

export default function* init() {
  yield takeLatest(ActionTypes.RequestDashboardEmployeeOptions, handleSagaErrors(handleRequestDashboardEmployeeOptions));
  yield takeEvery(ActionTypes.RequestChartData, handleSagaErrors(handleRequestChartData));
  yield takeLatest(ActionTypes.ExportDashboard, handleSagaErrors(handleExportDashboard));
}
