import { deepClone, findInObjectByString } from '../../helpers/utils';
import { ActionTypes } from './types';
import { touchAllFormFields } from './utils';

function formReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.RegisterForm: {
      const { formName, formObject, formModel, formState, initialState } = action.payload;

      const formClone = deepClone(state);
      formClone[formName] = {
        ...formClone[formName],
        state: formState,
        fields: formObject,
        model: formModel,
        initialState
      };
      return formClone;
    }

    case ActionTypes.UpdateFormState: {
      const { formName, formState } = action.payload;
      return {
        ...state,
        [formName]: {
          ...state[formName],
          state: formState
        }
      };
    }

    case ActionTypes.UpdateFormField: {
      const { formName, model, conditions } = action.payload;
      return {
        ...state,
        [formName]: {
          ...state[formName],
          model,
          conditions
        }
      };
    }

    case ActionTypes.ChangeFormFieldDataSource: {
      const { formName, fieldName, dataSource } = action.payload;
      const stateClone = deepClone(state);
      const formClone = stateClone[formName];
      if (formClone) {
        const field = findInObjectByString(formClone.fields, formClone.model[fieldName].path, false);
        if (field) {
          field.data = dataSource;
        }
      }
      return stateClone;
    }

    case ActionTypes.ChangeFormFieldDefiniton: {
      const { formName, fieldName, fieldDefinition } = action.payload;
      const stateClone = deepClone(state);
      const formClone = stateClone[formName];
      if (formClone) {
        const field = findInObjectByString(formClone.fields, formClone.model[fieldName].path, false);
        if (field) {
          const definitionKeys = Object.keys(fieldDefinition);
          definitionKeys.forEach(key => {
            field[key] = fieldDefinition[key];
          });
        }
      }
      return stateClone;
    }

    case ActionTypes.TouchForms: {
      const { formName } = action.payload;
      return {
        ...state,
        [formName]: {
          ...state[formName],
          model: touchAllFormFields(state[formName])
        }
      };
    }

    case ActionTypes.SubmitFailed: {
      return state;
    }

    case ActionTypes.DestroyForm: {
      const { formName } = action.payload;
      const formClone = deepClone(state);

      // TODO: might cause bugs
      if (formClone[formName] && formClone[formName].forms) {
        formClone[formName].forms.forEach(subForm => delete formClone[subForm]);
      }
      delete formClone[formName];

      return formClone;
    }

    default:
      return state;
  }
}

export default formReducer;
