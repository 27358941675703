import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import history from '../../history';
import handleSagaErrors from '../../common/handle-saga-errors';
import { ActionTypes, activateAccountForm, forgetPasswordForm, loginForm, resetPasswordForm } from './types';
import * as Actions from './actions';
import * as InitActions from '../../layouts/actions';
import {
  activateUserAccount,
  requestLogin,
  requestRefreshToken,
  requestUserAccount,
  requestPasswordReset,
  resetPassword,
  downloadTermsAndConditions,
  requestLanguageAndGender
} from '../../common/api';
import { USER_COOKIE_NAME } from '../../constants/cookie-names';
import { getLoggedInUser } from '../../helpers/authUtils';
import { flattenFormModel } from '../../common/form';
import { addSuccessNotification } from '../../common/notifications';
import { handleFileResponse } from '../../helpers/fileUtils';

function* handleLogin({ returnLocation }) {
  const store = yield select(s => s.Form);
  const form = store[loginForm];
  const flatForm = flattenFormModel(form);
  const response = yield call(requestLogin, flatForm);
  setSession({ ...response });

  yield put(Actions.loginUserSuccess(response));
  yield put(InitActions.initApp());

  if (returnLocation) {
    history.push(returnLocation);
    return;
  }
  history.push('/');
}

function* handleLogout() {
  setSession(null);
  yield call(() => {
    history.push('/authentication/login');
  });
  yield put(Actions.logoutUserSuccess())
}

const setSession = (jwt) => {
  const cookies = new Cookies();
  if (jwt) {
    cookies.set(USER_COOKIE_NAME, JSON.stringify(jwt), { path: '/' });
  } else {
    cookies.remove(USER_COOKIE_NAME, { path: '/' });
  }
};

let refreshTokenPromise: Promise<any>;
const getRefreshToken = (user) => requestRefreshToken({
  refreshToken: user.refreshToken
});

export async function refreshToken() {
  const user = getLoggedInUser();
  if (!refreshTokenPromise) {
    refreshTokenPromise = getRefreshToken(user)
      .then(result => {
        refreshTokenPromise = null;
        return result;
      });
  }

  return refreshTokenPromise.then(result => {
    user.jwtToken = result.jwtToken;
    user.refreshToken = result.refreshToken;
    setSession({ ...user });
    return true;
  }).catch(() => {
    return false;
  })
}

function* handleRequestUserAccount({ id }) {
  const response = yield call(requestUserAccount, id);
  yield put(Actions.requestUserAccountSuccess(response));
}

function* handleActivateUserAccount() {
  const store = yield select(s => s.Form);
  const form = store[activateAccountForm];
  const flatForm = flattenFormModel(form);
  const response = yield call(activateUserAccount, flatForm);
  addSuccessNotification('Account activated successfully');
  setSession({ ...response });
  yield put(Actions.loginUserSuccess(response));
  yield put(InitActions.initApp());
  history.push('/');
}

function* handleRequestPasswordReset() {
  const store = yield select(s => s.Form);
  const form = store[forgetPasswordForm];
  const flatForm = flattenFormModel(form);
  yield call(requestPasswordReset, flatForm);
  history.push('/authentication/confirm-mail');
}

function* handleResetPassword({ email, token }) {
  const store = yield select(s => s.Form);
  const form = store[resetPasswordForm];
  const flatForm = flattenFormModel(form);
  const body = {
    ...flatForm,
    email,
    token
  }
  yield call(resetPassword, body);
  addSuccessNotification('Password reset successfully');
  history.push('/authentication/login');
}

function* handleDownloadTermsAndConditions() {
  const response = yield call(downloadTermsAndConditions);
  const { blob } = response;
  handleFileResponse(blob, 'Move Privacy T&C.pdf');
}

function* handleLanguageAndGender() {
  const response = yield call(requestLanguageAndGender);
  yield put(InitActions.initAppSuccess(response));
}

export default function* init() {
  yield takeLatest(ActionTypes.LoginUser, handleSagaErrors(handleLogin));
  yield takeLatest(ActionTypes.LogoutUser, handleSagaErrors(handleLogout));
  yield takeLatest(ActionTypes.RequestUserAccount, handleSagaErrors(handleRequestUserAccount));
  yield takeLatest(ActionTypes.ActivateUserAccount, handleSagaErrors(handleActivateUserAccount));
  yield takeLatest(ActionTypes.RequestPasswordReset, handleSagaErrors(handleRequestPasswordReset));
  yield takeLatest(ActionTypes.ResetPassword, handleSagaErrors(handleResetPassword));
  yield takeLatest(ActionTypes.DownloadTermsAndConditions, handleSagaErrors(handleDownloadTermsAndConditions));
  yield takeLatest(ActionTypes.RequestLanguageAndGender, handleSagaErrors(handleLanguageAndGender));
}
