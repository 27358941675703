import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';
import AppContext from '../../context/Context';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import { getUserRole } from '../../helpers/authUtils';
import { deepClone } from '../../helpers/utils';
import { DASHBOARD_ROUTE } from '../../routes/types';
import PrivateRoute from '../../routes/private-route';

const NavbarVerticalMenu = ({ routes, location, dashboardClients = [] }) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  const { setShowBurgerMenu } = useContext(AppContext);

  useEffect(() => {
    let openedDropdown = null;
    routes.forEach((route, index) => {
      if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const getHr = name => {
    if (name === 'Widgets' || name === 'Documentation') {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
  };

  const mapToDashboardLocationRoute = (client, location) => ({
    to: `/dashboard/${client.id}/${location.id}`,
    name: location.name,
    route: PrivateRoute,
    exact: true
  });

  const getAccessibleMenuItems = (menuItems) => {
    const userRole = getUserRole();
    if (!userRole) {
      return [];
    }
    return menuItems.reduce((result, item) => {
      const clonedItem = deepClone(item);
      if (clonedItem.name === DASHBOARD_ROUTE) {
        if (dashboardClients.length === 1) {
          const client = dashboardClients[0];
          clonedItem.children = client.locations.map(location => mapToDashboardLocationRoute(client, location));
        } else {
          clonedItem.children = dashboardClients.map(client => ({
            name: client.name,
            children: client.locations.map(location => mapToDashboardLocationRoute(client, location))
          }));
        }
      } else if (clonedItem.children && clonedItem.children.length > 0) {
        clonedItem.children = getAccessibleMenuItems(clonedItem.children);
        // No accessible children - so remove item
        if (clonedItem.children.length === 0) {
          return result;
        }
      }

      let isAccessible = true;
      if (clonedItem.roles && Array.isArray(clonedItem.roles)) {
        isAccessible = clonedItem.roles.includes(userRole);
      } else if (clonedItem.roles) {
        isAccessible = userRole.includes(clonedItem.roles);
      }
      if (isAccessible) {
        return result.concat([clonedItem]);
      }
      return result;
    }, []);
  };

  const accessibleRoutes = getAccessibleMenuItems(routes);

  return accessibleRoutes.map((route, index) => {
    if (!route.children) {
      return (
        <Fragment key={index}>
          {getHr(route.name)}
          <NavItem>
            <NavLink className="nav-link" {...route} onClick={() => setShowBurgerMenu(false)}>
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment key={index}>
        {getHr(route.name)}
        <NavItem>
          <BootstrapNavLink
            onClick={e => toggleOpened(e, index)}
            className="dropdown-indicator cursor-pointer"
            aria-expanded={openedIndex === index}
          >
            <NavbarVerticalMenuItem route={route} />
          </BootstrapNavLink>
          <Collapse isOpen={openedIndex === index}>
            <Nav>
              <NavbarVerticalMenu routes={route.children} location={location} />
            </Nav>
          </Collapse>
        </NavItem>
      </Fragment>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  dashboardClients: state.App.dashboardClients
});

export default connect(mapStateToProps, null)(withRouter(NavbarVerticalMenu));
