export default class ApiError extends Error {
  status: number;
  code: string;
  validation: object;

  constructor(status: number, message: string, code?: string, validation?: string) {
    super(message);

    this.status = status;
    this.code = code;
    this.validation = validation && JSON.parse(validation);
  }
}
