import { ActionTypes } from './types';

export const defaultState = {
  extendedNomenclatures: {}
};

function nomenclaturesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestExtendedNomenclaturesSuccess:
      return {
        ...state,
        extendedNomenclatures: action.payload
      };
    case ActionTypes.SetEditNomModel:
      return {
        ...state,
        editNomModel: action.nomObject
      };
    case ActionTypes.CleanExtendedNomenclatures:
      return defaultState;
    default:
      return state;
  }
}

export default nomenclaturesReducer;
