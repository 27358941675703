import { call, put, select, takeLatest } from 'redux-saga/effects';
import handleSagaErrors from '../../common/handle-saga-errors';
import { ActionTypes, userPasswordChangeForm, userProfileSettingsForm } from './types';
import * as Actions from './actions';
import {
  requestMyGeneralDetails,
  updateMyGeneralDetails,
  requestMyAvatar,
  updateMyAvatar,
  updateMyPassword
} from '../../common/api';
import { addSuccessNotification } from '../../common/notifications';
import { flattenFormModel } from '../../common/form';
import { setNewLanguageCookie } from '../../helpers/langUtils';
import * as InitActions from '../../layouts/actions';

function* handleRequestMyGeneralDetails() {
  const response = yield call(requestMyGeneralDetails);
  yield put(Actions.requestMyGeneralDetailsSuccess(response));
}

function* handleUpdateMyGeneralDetails() {
  const formStore = yield select(s => s.Form);
  const profileSettingsForm = formStore[userProfileSettingsForm];
  const flatForm = flattenFormModel(profileSettingsForm);

  const { languageId } = flatForm;
  const nomsStore = yield select(s => s.App.noms);
  const { languages } = nomsStore;
  const newLanguage = languages.find(x => x.value === languageId);
  setNewLanguageCookie(newLanguage);
  yield put(InitActions.saveUserLanguage(newLanguage));

  const response = yield call(updateMyGeneralDetails, flatForm);
  yield put(Actions.requestMyGeneralDetailsSuccess(response));
  addSuccessNotification("Profile updated successfully");
}

function* handleRequestMyAvatar() {
  const response = yield call(requestMyAvatar);
  yield put(Actions.requestMyAvatarSuccess(response));
}

function* handleUploadNewAvatar({ newAvatarFile }) {
  const formData = new FormData();
  formData.append('avatarFile', newAvatarFile);
  const response = yield call(updateMyAvatar, formData);
  addSuccessNotification('New avatar uploaded successfully');
  yield put(Actions.requestMyAvatarSuccess(response));
}

function* handleUpdateMyPassword() {
  const store = yield select(s => s.Form);
  const passwordsForm = store[userPasswordChangeForm];
  const flatForm = flattenFormModel(passwordsForm);
  yield call(updateMyPassword, flatForm);
  addSuccessNotification('Password changed successfully');
}

export default function* init() {
  yield takeLatest(ActionTypes.RequestMyGeneralDetails, handleSagaErrors(handleRequestMyGeneralDetails));
  yield takeLatest(ActionTypes.UpdateMyGeneralDetails, handleSagaErrors(handleUpdateMyGeneralDetails));
  yield takeLatest(ActionTypes.RequestMyAvatar, handleSagaErrors(handleRequestMyAvatar));
  yield takeLatest(ActionTypes.UploadNewAvatar, handleSagaErrors(handleUploadNewAvatar));
  yield takeLatest(ActionTypes.UpdateMyPassword, handleSagaErrors(handleUpdateMyPassword));
}
