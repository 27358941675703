// @flow
import * as React from 'react';
import { Col } from 'reactstrap';

function FormGroup({ size = 12, id, className, children }) {
  return (
    <Col md={size} id={id}>
      <div className={`${className} form-group`}>
        {children}
      </div>
    </Col>
  );
}

export default FormGroup;
