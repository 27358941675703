// @flow
import React from 'react';
import history from 'src/history';
import { connect } from 'react-redux';
import { areDeeplyEqual } from '../../helpers/utils';

import * as Actions from './actions';

class FormWrapper extends React.Component {

  isFormPristine = true;

  componentDidMount() {
    const { formName, fields, model, mode } = this.props;
    this.props.initForm(formName, fields, model, mode);
  }

  componentDidUpdate(prevProps) {
    const { updateForm, formName, fields, model, form } = this.props;

    this.setCheckForUnsavedChanges(form[formName]);

    if (areDeeplyEqual(prevProps.fields, fields) && areDeeplyEqual(prevProps.model, model)) {
      return;
    }

    updateForm(formName, fields, model);
  }

  componentWillUnmount() {
    this.props.destroyForm(this.props.formName);
  }

  onChangeFormField = (fieldName, value) => {
    const { formName, changeFormField, onFormFieldChange } = this.props;
    changeFormField(formName, fieldName, value);
    onFormFieldChange && onFormFieldChange(fieldName, value);
  };

  setCheckForUnsavedChanges = (formObject) => {
    if (!formObject) {
      return;
    }

    if (this.checkForUnsavedChanges) {
      const { state } = formObject;
      this.isFormPristine = state.pristine;
      window.onbeforeunload = () => (!this.isFormPristine ? false : null);

      if (!this.isFormPristine) {
        this.historyBlock = history.block((location, action) => 'Changes you made may not be saved.');
      } else {
        this.historyBlock();
      }
    }
  };

  onFormSubmit = (callback, skipValidations = false) => {
    if (!skipValidations) {
      const { submitForm, formName } = this.props;
      submitForm(formName, callback);
    } else {
      callback();
    }
  };

  render() {
    const { form, formName } = this.props;
    if (!form[formName]) {
      return null;
    }

    const { model, fields, state } = form[formName];
    if (!model) {
      return null;
    }
    let formFields = fields;

    return (
      <div>
        {
          this.props.renderChildren(
            formFields,
            model,
            state,
            this.onChangeFormField
          )
        }
        {
          this.props.renderSubmitChildren && this.props.renderSubmitChildren(this.onFormSubmit)
        }
      </div>
    );
  }
}

const mapStoreToProps = (state) => ({
  form: state.Form
});

export default connect(mapStoreToProps, Actions)(FormWrapper);
