import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './types';
import * as Actions from './actions';
import {
  requestExtendedNomenclatures,
  updateNomenclatureEntry,
  addNomenclatureEntry
} from '../../../common/api';
import handleSagaErrors from '../../../common/handle-saga-errors';

function* handleRequestExtendedNomenclatures() {
  const response = yield call(requestExtendedNomenclatures);
  yield put(Actions.requestExtendedNomenclaturesSuccess(response));
}

function* handleUpdateNomenclature({ nomType, callback }) {
  const state = yield select(s => s.Nomenclatures);
  const { editNomModel } = state;
  let response = null;
  if (editNomModel.value === null) {
    response = yield call(addNomenclatureEntry, nomType, editNomModel);
  } else {
    response = yield call(updateNomenclatureEntry, nomType, editNomModel);
  }
  yield put(Actions.requestExtendedNomenclaturesSuccess(response));
  callback && callback();
}

export default function* init() {
  yield takeLatest(ActionTypes.RequestExtendedNomenclatures, handleSagaErrors(handleRequestExtendedNomenclatures));
  yield takeLatest(ActionTypes.UpdateNomenclature, handleSagaErrors(handleUpdateNomenclature))
}