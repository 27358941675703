import { ActionTypes } from './types';

export const defaultState = {
  dashboardEmployees: []
};

function dashboardReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.RequestDashboardEmployeeOptionsSuccess:
      return {
        ...state,
        dashboardEmployees: action.payload
      };

    case ActionTypes.RequestChartDataSuccess: {
      const { dashboard, chart, payload } = action;
      const { data } = payload;
      return {
        ...state,
        [dashboard]: {
          ...state[dashboard],
          [chart]: { ...data }
        }
      };
    }
    default:
      return state;
  }
}

export default dashboardReducer;
