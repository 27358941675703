import React from 'react';
import euLogo from '../../../src/assets/img/logos/logoEUleft.png'
import opicLogo from '../../../src/assets/img/logos/logoOPICright.png'
import Translate from '../../components/translate';

const Footer = () => (
  <footer className="justify-content-between text-center fs--1 mt-4 mb-3" style={{backgroundColor: '#fff', position: 'relative'}}>
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <img src={opicLogo} width="110" height="110" class="logo-img" className="mr-4"></img>
      <div>
      <p className="mt-2">{<Translate id="pages.footer.project.number"/>}</p>
      <p className="mr-4">{<Translate id="pages.footer.project.name"/>}</p>
      <p>{<Translate id="pages.footer.project.beneficiary"/>}</p>
      </div>
      <img src={euLogo} width="110" height="110" class="logo-img"></img>
    </div>
  </footer>
);

export default Footer;