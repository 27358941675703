export const ActionTypes = {
  InitApp: 'move/pages/main/InitApp',
  InitAppSuccess: 'move/pages/main/InitAppSuccess',
  RequestNomencatures: 'move/pages/main/RequestNomencatures',
  RequestNomencaturesSuccess: 'move/pages/main/RequestNomencaturesSuccess',
  RequestDashboardClients: 'move/pages/main/RequestDashboardClients',
  RequestDashboardClientsSuccess: 'move/pages/main/RequestDashboardClientsSuccess',
  SaveUserLanguage: 'move/pages/main/SaveUserLanguage'
};

export const DEFAULT_LANG_ID = 1;
export const DEFAULT_LANG_CODE = 'EN';
