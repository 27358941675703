import { call, put } from 'redux-saga/effects';

import { logoutUser } from '../pages/authentication/actions';
import { addErrorNotification } from './notifications';
import { refreshToken } from '../pages/authentication/saga';

import { SERVER_ERROR_CODES } from '../constants/server-error-codes';

function handleSagaErrors(saga) {
  return function* callSaga(action) {
    try {
      yield call(saga, action);
    } catch (error) {
      if (error.status === 401) {
        const isRefreshed = yield call(refreshToken);
        if (isRefreshed) {
          yield call(saga, action);
          return;
        }
        yield put(logoutUser());
      }
      addErrorNotification(error.message);
    }
  };
}

export default handleSagaErrors;
