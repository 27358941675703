import { USER_ROLES } from '../constants/user-roles';
import PrivateRoute from './private-route';
import { DASHBOARD_ROUTE } from './types';

export const homePageRoute = {
  name: 'Home page',
  to: '/',
  exact: true,
  icon: 'home',
  roles: [USER_ROLES.GLOBAL_ADMIN],
  route: PrivateRoute
};

export const administrationRoutes = {
  name: 'Administration',
  to: '/administration',
  exact: true,
  icon: 'user-cog',
  roles: [USER_ROLES.GLOBAL_ADMIN, USER_ROLES.COMPANY_ADMIN],
  children: [
    {
      to: '/administration/devices',
      name: 'Devices',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN],
      exact: true
    },
    {
      to: '/administration/wearables',
      name: 'Wearables',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN],
      exact: true
    },
    {
      to: '/administration/users',
      name: 'Users',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN, USER_ROLES.COMPANY_ADMIN],
      exact: true
    },
    {
      to: '/administration/roles-permissions',
      name: 'Roles and Permissions',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN],
      exact: true
    },
    {
      to: '/administration/nomenclatures',
      name: 'Nomenclatures',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN],
      exact: true
    }
  ]
};

export const clientRoutes = {
  name: 'Clients',
  to: '/clients',
  exact: true,
  icon: 'building',
  roles: [USER_ROLES.GLOBAL_ADMIN, USER_ROLES.COMPANY_ADMIN],
  children: [
    {
      to: '/clients',
      name: 'Clients',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN, USER_ROLES.COMPANY_ADMIN],
      exact: true
    },
    {
      to: '/clients/contact-persons',
      name: 'Contact Persons',
      route: PrivateRoute,
      roles: [USER_ROLES.GLOBAL_ADMIN, USER_ROLES.COMPANY_ADMIN],
      exact: true
    }
  ]
};

export const aboutRoute = {
  name: 'About',
  to: '/about',
  exact: true,
  icon: 'exclamation-circle',
  roles: [USER_ROLES.GLOBAL_ADMIN],
  route: PrivateRoute
};

export const dashboardRoutes = {
  name: DASHBOARD_ROUTE,
  to: 'dashboard/:clientId/:locationId/:parameter?',
  exact: true,
  icon: 'chart-pie',
  children: []
};

export default [
  homePageRoute,
  dashboardRoutes,
  administrationRoutes,
  clientRoutes,
  aboutRoute,
];
