export const ActionTypes = {
    RequestDevices: 'RequestDevices',
    RequestDevicesSuccess: 'RequestDevicesSuccess',
    CreateNewDevice: 'move/pages/devices/CreateNewDevice',
    Reassign: 'move/pages/devices/Reassign',
    UpdateDevice: 'move/pages/devices/UpdateDevice',
    RequestHistorySuccess: 'move/pages/devices/RequestHistorySuccess',
    RequestHistory: 'move/pages/devices/RequestHistory',
    GetDeviceDetails: 'move/pages/devices/GetDeviceDetails',
    RequestGetDeviceDetailsSuccess: 'move/pages/devices/RequestGetDeviceDetailsSuccess',
    Assign: 'move/pages/devices/Assign',
    AssignDevice: 'move/pages/devices/AssignDevice',
  };

  export const reassignForm = 'reassignForm';
  export const assignForm = 'assignForm';
  export const updateDeviceForm = 'updateDeviceForm';
  export const deviceForm = 'deviceForm';

  export const CLIENT_ID_FIELD = 'clientId';
  export const LOCATION_ID_FIELD = 'locationId';
  