import { getUserToken } from "../helpers/authUtils";
import ApiError from './api-error';
import * as QS from 'qs';
import { getLangId } from "../helpers/langUtils";

const fileTypes = [
  'application/octet-stream',
  'application/zip',
  'application/pdf',
  'application/vnd.ms-excel',
  'text/csv; charset=UTF-8',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.oasis.opendocument.text',
  'image/png',
  'image/jpeg',
  'audio/mpeg',
  'video/mpeg',
  'video/mp4',
  'video/webm'
];

export const apiUrl = process.env.REACT_APP_API_HOST;

export const request = async (
  endpoint, method, body = undefined, authorisation = true, additionalHeaders = {}, isSelfCall = false
) => {
  let headers = {
    Accept: 'application/json',
    ...(body instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
    ...(authorisation ? {
      Authorization: `Bearer ${getUserToken()}`,
    } : {}),
    ...additionalHeaders
  };

  const response = await fetch(isSelfCall ? endpoint : apiUrl + endpoint, {
    headers,
    method,
    body: body instanceof FormData ? body : JSON.stringify(body)
  });

  if (!response.ok) {
    if (response.status === 400 && response.url.indexOf('clientsettings/client') !== -1) {
      return response;
    }
    if (response.status === 401) {
      throw new ApiError(response.status, 'Unauthorized');
    }
    if (response.status === 403) {
      throw new ApiError(response.status, 'Forbidden');
    }
    return response.json().then((res) => {
      let { message, status, code } = res;

      if (!message) {
        // handle case with Internal Server Error 500
        message = 'Oops, something went wrong. Please try again later';
      }

      throw new ApiError(res.status, message, code, res.validation);
    });
  }

  if (fileTypes.includes(response.headers.get('Content-Type'))) {
    return {
      blob: await response.blob()
    }
  }

  if (response.status === 204) {
    return {};
  }

  if (!response.headers.get('Content-Type')) {
    return response
      .json()
      .catch((err) => {
        // Fix for empty response body
        console.error(`'${err}' happened, but no big deal!`);
        return {};
      });
  }

  if (response.headers.get('x-pagination')) {
    const pagedResponse = {
      result: await response.json(),
      pageParameters: JSON.parse(response.headers.get('x-pagination'))
    };

    return pagedResponse;
  }

  return response.json();
};

// Init App
export const requestNomenclatures = async () => request(`api/${getLangId()}/nomenclatures/list-all-noms`, 'GET');
export const requestLanguageAndGender = async () => request(`api/nomenclatures/list-language-and-gender`, 'GET');
export const requestDashboardClients = async () => request(`api/${getLangId()}/nomenclatures/list-dashboard-clients`, 'GET');
export const requestEmployeeOptions = async (clientId, locationId) => request(`api/${getLangId()}/nomenclatures/list-dropdown-dashboard-employees/${clientId}/${locationId}`, 'GET');

// Authentication
export const requestLogin = async (body) => request('api/accounts/login', 'POST', body, false);
export const requestRefreshToken = async (body) => request('api/accounts/refresh-token', 'POST', body, false);
export const requestUserAccount = async (id) => request(`api/accounts/get-user-account/${id}`, 'GET');
export const activateUserAccount = async (body) => request('api/accounts/activate-user-account', 'POST', body);
export const requestPasswordReset = async (body) => request('api/accounts/request-password-reset', 'POST', body);
export const resetPassword = async (body) => request('api/accounts/reset-password', 'POST', body);

// User Management
export const requestListUsers = async (queryParamsObject) => {
  const mainPath = `api/${getLangId()}/user-management/list-users`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const requestUserAvatar = async (userId) => request(`api/${getLangId()}/user-management/get-user-avatar/${userId}`, 'GET');
export const requestUserGeneralDetails = async (userId) => request(`api/${getLangId()}/user-management/get-user-general-details/${userId}`, 'GET');
export const updateUserAvatar = async (userId, body) => request(`api/${getLangId()}/user-management/update-user-avatar/${userId}`, 'PUT', body);
export const updateUserGeneralDetails = async (userId, body) => request(`api/${getLangId()}/user-management/update-user-general-details/${userId}`, 'PUT', body);
export const requestUserAdministrationSettings = async (userId) => request(`api/${getLangId()}/user-management/get-user-administration-settings/${userId}`, 'GET');
export const updateUserAdministrationSettings = async (userId, body) => request(`api/${getLangId()}/user-management/update-user-administration-settings/${userId}`, 'POST', body);
export const createNewUser = async (body) => request(`api/${getLangId()}/user-management/create-new-user`, 'POST', body);
export const exportUsers = async () => request(`api/${getLangId()}/user-management/export-users`, 'GET');
export const importUsers = async (body) => request(`api/${getLangId()}/user-management/import-users`, 'POST', body);
export const exportInviteTemplate = async () => request(`api/${getLangId()}/user-management/export-invite-template`, 'GET');
export const importInviteTemplate = async (body) => request(`api/${getLangId()}/user-management/import-invite-template`, 'POST', body);
export const resendUserInvitations = async (body) => request(`api/${getLangId()}/user-management/resend-invitations`, 'POST', body);

// Nomenclatures
export const requestExtendedNomenclatures = async () => request(`api/${getLangId()}/nomenclatures/list-all-extended-noms`, 'GET');
export const updateNomenclatureEntry = async (nomType, body) => request(`api/${getLangId()}/nomenclatures/update-nomenclature-entry/${nomType}`, 'POST', body);
export const addNomenclatureEntry = async (nomType, body) => request(`api/${getLangId()}/nomenclatures/add-nomenclature-entry/${nomType}`, 'POST', body);
export const listDropdownClients = async () => request(`api/${getLangId()}/nomenclatures/list-dropdown-clients`, 'GET');
export const listDropdownLocations = async (clientId) => request(`api/${getLangId()}/nomenclatures/list-client-locations/${clientId}`, 'GET');
export const listDropdownUsers = async (clientId) => request(`api/${getLangId()}/nomenclatures/list-users/${clientId}`, 'GET');

// Current user
export const requestMyGeneralDetails = async () => request(`api/${getLangId()}/user-profile/get-my-general-details`, 'GET');
export const requestMyAvatar = async () => request(`api/${getLangId()}/user-profile/get-my-avatar`, 'GET');
export const updateMyAvatar = async (body) => request(`api/${getLangId()}/user-profile/update-my-avatar`, 'PUT', body);
export const updateMyGeneralDetails = async (body) => request(`api/${getLangId()}/user-profile/update-my-general-details`, 'PUT', body);
export const updateMyPassword = async (body) => request(`api/${getLangId()}/user-profile/change-my-password`, 'PUT', body);

// Clients
export const requestClients = () => request(`api/${getLangId()}/clients/list`, 'GET');
export const createNewClient = (body) => request(`api/${getLangId()}/clients/add-new-client`, 'POST', body);
export const updateClient = (id, body) => request(`api/${getLangId()}/clients/${id}/update-client-details`, 'PUT', body);
export const requestClientDetails = (id) => request(`api/${getLangId()}/clients/${id}/get-client-details`, 'GET');
export const requestClientLocations = (id, queryParamsObject) => {
  const mainPath = `api/${getLangId()}/clients/${id}/locations/list`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const requestClientLocationDetails = (clientId, locationId) => request(`api/${getLangId()}/clients/${clientId}/location/${locationId}`, 'GET');
export const createNewClientLocation = (clientId, body) => request(`api/${getLangId()}/clients/${clientId}/location/add-new`, 'POST', body);
export const updateClientLocation = (clientId, locationId, body) => request(`api/${getLangId()}/clients/${clientId}/location/${locationId}`, 'PUT', body);
export const requestListClientContactPersons = (queryParamsObject) => {
  const mainPath = `api/${getLangId()}/clients/list-contact-persons`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const exportClientContactPersons = async () => request(`api/${getLangId()}/clients/export-contact-persons`, 'GET');
export const getContactPersonDetails = async (id) => request(`api/${getLangId()}/clients/get-contact-person-details/${id}`, 'GET');
export const updateContactPerson = async (id, body) => request(`api/${getLangId()}/clients/update-contact-person/${id}`, 'PUT', body);

// Devices
export const createNewDevice = async (body) => request(`api/${getLangId()}/devices/create-device`, 'POST', body);
export const requestListDevices = async (queryParamsObject) => {
  const mainPath = `api/${getLangId()}/devices/list-devices`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const updateDevice = async (deviceId, body) => request(`api/${getLangId()}/devices/update-device/${deviceId}`, 'PUT', body);
export const reassign = async (body) => request(`api/${getLangId()}/devices/reassign`, 'PUT', body);
export const requestListHistory = async (id, queryParamsObject) => {
  const mainPath = `api/${getLangId()}/devices/list-history/${id}`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const getDeviceDetails = async (id) => request(`api/${getLangId()}/devices/get-device-details/${id}`, 'GET');
export const assign = async (body) => request(`api/${getLangId()}/devices/assign`, 'PUT', body);

// Misc
export const downloadTermsAndConditions = async () => request('api/accounts/download-terms-conditions', 'GET');

// Wearables
export const createNewWearable = async (body) => request(`api/${getLangId()}/wearables/create-wearable`, 'POST', body);
export const requestListWearables = async (queryParamsObject) => {
  const mainPath = `api/${getLangId()}/wearables/list-wearables`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const updateWearable = async (wearableId, body) => request(`api/${getLangId()}/wearables/update-wearable/${wearableId}`, 'PUT', body);
export const reassignWearable = async (body) => request(`api/${getLangId()}/wearables/reassign`, 'PUT', body);
export const requestListWearableHistory = async (id, queryParamsObject) => {
  const mainPath = `api/${getLangId()}/wearables/list-history/${id}`;
  const queryString = QS.stringify(queryParamsObject);
  const fullPath = `${mainPath}?${queryString}`;
  return request(fullPath, 'GET');
};
export const getWearableDetails = async (id) => request(`api/${getLangId()}/wearables/get-wearable-details/${id}`, 'GET');
export const assignWearable = async (body) => request(`api/${getLangId()}/wearables/assign`, 'PUT', body);