import { ActionTypes } from './types';

export const requestClients = () => ({
  type: ActionTypes.RequestClients
});

export const requestClientsSuccess = (payload) => ({
  type: ActionTypes.RequestClientsSuccess,
  payload
});

export const requestClientDetails = (id) => ({
  type: ActionTypes.RequestClientDetails,
  id
});

export const requestClientDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestClientDetailsSuccess,
  payload
});

export const submitClientDetails = (id) => ({
  type: ActionTypes.SubmitClientDetails,
  id
});

export const cleanClientDetails = () => ({
  type: ActionTypes.CleanClientDetails
});

export const requestClientLocations = (clientId, pageParameters) => ({
  type: ActionTypes.RequestClientLocations,
  clientId,
  pageParameters
});

export const requestClientLocationsSuccess = (payload) => ({
  type: ActionTypes.RequestClientLocationsSuccess,
  payload
});

export const requestClientLocationDetails = (clientId, locationId) => ({
  type: ActionTypes.RequestClientLocationDetails,
  clientId,
  locationId
});

export const requestClientLocationDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestClientLocationDetailsSuccess,
  payload
});

export const cleanClientLocationDetails = () => ({
  type: ActionTypes.CleanClientLocationDetails,
});

export const submitClientLocationDetails = (clientId, locationId) => ({
  type: ActionTypes.SubmitClientLocationDetails,
  clientId,
  locationId
});

export const requestClientContactPersons = (pageParameters) => ({
  type: ActionTypes.RequestClientContactPersons,
  pageParameters
});

export const requestClientContactPersonsSuccess = (payload) => ({
  type: ActionTypes.RequestClientContactPersonsSuccess,
  payload
});

export const exportContactPersons = () => ({
  type: ActionTypes.ExportContactPersons,
});

export const getContactPersonDetails = (id) => ({
  type: ActionTypes.GetContactPersonDetails,
  id
});

export const requestGetContactPersonDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestGetContactPersonDetailsSuccess,
  payload
});

export const updateContactPerson = (id, callback) => ({
  type: ActionTypes.UpdateContactPerson,
  id,
  callback
});