import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Row, Col, FormGroup, Label } from 'reactstrap';

const LogoUploader = ({ onLogoUpload, logoUrl }) => {
  const [newLogo, setNewLogo] = useState(null);

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    accept: 'image/jpg, image/png, image/jpeg',
    onDrop: acceptedFiles => {
      if (!acceptedFiles.length) {
        return;
      }
      const newLogoFile = acceptedFiles[0];
      setNewLogo(Object.assign(newLogoFile, {
        preview: URL.createObjectURL(newLogoFile)
      }));
      onLogoUpload(newLogoFile);
    }
  });

  const imageUrl = newLogo ? newLogo.preview : logoUrl;

  return (
    <Row>
      <Col md={12} sm={12}>
        <FormGroup>
          <Label>Logo</Label>
          <div className="mb-2">
            {imageUrl ? (
              <img src={imageUrl} alt="" style={{ width: '80%', height: 'auto' }} />
            ) : (
              <div>No logo uploaded</div>
            )}
          </div>
        </FormGroup>
      </Col>
      <Col md={12} sm={12}>
        <div {...getRootProps({className: 'dropzone-area'})}>
          <input {...getInputProps()} />
          <div className="upload-description">Drop file here or click to upload.</div>
        </div>
      </Col>
      </Row>
  )
}

export default LogoUploader;
