import { FIELD_TYPES } from '../../common/form';
import { IS_NEW_ID } from './types';

export const mainClientFieldsDefinitions = (noms) => [
  {
    id: 'defaultProperties',
    type: FIELD_TYPES.GRID,
    md: 12,
    children: [
      {
        id: 'generalInformationGroup',
        type: FIELD_TYPES.SECTION,
        name: 'pages.clients.client.details.section.title',
        children: [
          {
            id: 'formGroup',
            type: FIELD_TYPES.FORM_GROUP,
            children: [
              {
                id: 'id',
                type: FIELD_TYPES.HIDDEN
              },
              {
                id: 'name',
                name: 'generic.name',
                type: FIELD_TYPES.TEXT,
                validations: [
                  { type: 'required', message: 'generic.error.name.required' }
                ],
              },
              {
                id: 'countryId',
                name: 'generic.country',
                type: FIELD_TYPES.DROPDOWN,
                data: noms.countries,
                validations: [
                  { type: 'required', message: 'generic.error.country.required' }
                ],
              },
              {
                id: 'address',
                name: 'generic.address',
                type: FIELD_TYPES.TEXTAREA,
              },
              {
                id: 'logoUrl',
                name: 'generic.logo',
                type: FIELD_TYPES.LOGO
              }
            ]
          }
        ]
      }
    ]
  }
];

export const contactPersonFieldDefinitions = (sectionName) => [
  {
    id: 'defaultProperties',
    type: FIELD_TYPES.GRID,
    children: [
      {
        id: 'generalInformationGroup',
        type: FIELD_TYPES.SECTION,
        name: sectionName,
        children: [
          {
            id: 'formGroup',
            type: FIELD_TYPES.FORM_GROUP,
            children: [
              {
                id: 'id',
                type: FIELD_TYPES.HIDDEN,
              },
              {
                id: 'firstName',
                name: 'generic.first.name',
                type: FIELD_TYPES.TEXT,
                validations: [
                  { type: 'required', message: 'generic.error.first.name.required' }
                ],
              },
              {
                id: 'lastName',
                name: 'generic.last.name',
                type: FIELD_TYPES.TEXT,
                validations: [
                  { type: 'required', message: 'generic.error.last.name.required' }
                ],
              },
              {
                id: 'companyTitle',
                name: 'generic.company.title',
                type: FIELD_TYPES.TEXT
              },
              {
                id: 'phoneNumber',
                name: 'generic.phone.number',
                type: FIELD_TYPES.TEXT,
                validations: [
                  { type: 'required', message: 'generic.error.phone.number.required' },
                  { type: 'phoneNumber', message: 'generic.error.phone.not.valid' }
                ]
              },
              {
                id: 'email',
                name: 'generic.email',
                type: FIELD_TYPES.EMAIL,
                validations: [
                  { type: 'required', message: 'generic.error.email.required' },
                  { type: 'email', message: 'generic.error.email.not.valid' }
                ],
              },
              {
                id: 'notes',
                name: 'generic.notes',
                type: FIELD_TYPES.TEXTAREA
              }
            ]
          },
        ]
      },
    ]
  }
];

export const mainLocationFieldDefinitions = (noms, isNew = IS_NEW_ID) => [
  {
    id: 'defaultProperties',
    type: FIELD_TYPES.GRID,
    md: 12,
    children: [
      {
        id: 'generalInformationGroup',
        type: FIELD_TYPES.SECTION,
        name: 'pages.clients.location.details.form.title',
        children: [
          {
            id: 'formGroup',
            type: FIELD_TYPES.FORM_GROUP,
            children: [
              {
                id: 'id',
                type: FIELD_TYPES.HIDDEN
              },
              ...(isNew !== IS_NEW_ID ? [{
                id: 'clientName',
                name: 'pages.clients.location.details.form.client.name.field',
                type: FIELD_TYPES.TEXT,
                disabled: true
              }] : []),
              ,
              ...(isNew !== IS_NEW_ID ? [
                {
                id: 'deviceName',
                name: 'pages.clients.location.details.form.device.name.field',
                type: FIELD_TYPES.TEXT,
                disabled: true
              }] : []),
              {
                id: 'name',
                name: 'pages.clients.location.details.form.location.name.field',
                type: FIELD_TYPES.TEXT,
                validations: [
                  { type: 'required', message: 'generic.error.name.required' }
                ],
              },
              {
                id: 'countryId',
                name: 'generic.country',
                type: FIELD_TYPES.DROPDOWN,
                data: noms.countries,
                validations: [
                  { type: 'required', message: 'generic.error.country.required' }
                ],
              },
              {
                id: 'address',
                name: 'generic.address',
                type: FIELD_TYPES.TEXTAREA,
              },
              {
                id: 'active',
                name: 'pages.clients.location.details.form.active.field',
                type: FIELD_TYPES.TOGGLE_SWITCH
              }
            ]
          }
        ]
      }
    ]
  }
];
