import { call, put, takeLatest, select } from 'redux-saga/effects';
import history from '../../../history';
import handleSagaErrors from '../../../common/handle-saga-errors';
import { ActionTypes, reassignForm, assignForm, deviceForm, updateDeviceForm, CLIENT_ID_FIELD, LOCATION_ID_FIELD } from './types';
import * as Actions from './actions';
import { addSuccessNotification } from '../../../common/notifications';
import { 
  createNewDevice,
  requestListDevices,
  reassign,
  requestListHistory,
  updateDevice,
  listDropdownLocations,
  getDeviceDetails,
  assign
 } from '../../../common/api';
import { DefaultPageParameters, DeviceHistoryPageParameters } from '../../../constants/pagination';
import { flattenFormModel } from '../../../common/form';
import { ActionTypes as FormActionTypes } from '../../../common/form/types';
import * as FormActions from '../../../common/form/actions';

function* handleRequestDevices({ pageParameters = DefaultPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestListDevices, queryParamsObject);
  yield put(Actions.requestDevicesSuccess(response));
}

function* handleCreateNewDevice() {
  const store = yield select(s => s.Form);
  const newDeviceForm = store[deviceForm];
  const flatForm = flattenFormModel(newDeviceForm);
  const response = yield call(createNewDevice, flatForm);
  addSuccessNotification("New device created successfully");
  history.push(`/administration/devices`);
}

function* handleUpdateDevice({ id, callback }) {
  const store = yield select(s => s.Form);
  const updateForm = store[updateDeviceForm];
  const flatForm = flattenFormModel(updateForm);
  const response = yield call(updateDevice, id, flatForm);
  addSuccessNotification('New device updated successfully');
  yield put(Actions.requestGetDeviceDetailsSuccess(response));
  callback && callback();
}

function* handleReassign({ callback, id }) {
  const store = yield select(s => s.Form);
  const reassignDeviceForm = store[reassignForm];
  const flatForm = flattenFormModel(reassignDeviceForm);
  const body = {
    id,
    ...flatForm
  };
  yield call(reassign, body);
  addSuccessNotification("Reassign successfully");
  yield put(Actions.requestHistory(id));
  callback && callback();
}

function* handleAssign({ callback, id }) {
  const store = yield select(s => s.Form);
  const assignDeviceForm = store[assignForm];
  const flatForm = flattenFormModel(assignDeviceForm);
  const body = {
    id,
    ...flatForm
  };
  yield call(assign, body);
  addSuccessNotification("Assign successfully");
  callback && callback();
}

function* handleRequestHistory({ id, pageParameters = DeviceHistoryPageParameters }) {
  const queryParamsObject = {
    pageNumber: pageParameters.pageNumber,
    pageSize: pageParameters.pageSize,
    orderBy: pageParameters.orderBy,
    searchTerm: pageParameters.searchTerm
  };
  const response = yield call(requestListHistory, id, queryParamsObject);
  yield put(Actions.requestHistorySuccess(response));
}

function* handleGetDeviceDetails({id}) {
  const response = yield call(getDeviceDetails, id);
  yield put(Actions.requestGetDeviceDetailsSuccess(response));
}

function* handleAfterChangeFormField({ payload }) {
  const { formName, fieldName } = payload;
  const state = yield select();
  const formState = state.Form[formName];
  if (!formState) {
    return;
  }
  const fieldModel = formState.model[fieldName];

  if (formName === assignForm || formName === reassignForm) {
    if (fieldName === CLIENT_ID_FIELD) {
      const clientId = fieldModel.value.value;
      const locations = yield call(listDropdownLocations, clientId);
      yield put(FormActions.changeFormFieldDefiniton(formName, LOCATION_ID_FIELD, { hidden: clientId === null }));
      yield put(FormActions.changeFormFieldDataSource(formName, LOCATION_ID_FIELD, locations));
      yield put(FormActions.changeFormField(formName, LOCATION_ID_FIELD, null));
    }
  }
}

export default function* init() {
  yield takeLatest(ActionTypes.RequestDevices, handleSagaErrors(handleRequestDevices));
  yield takeLatest(ActionTypes.CreateNewDevice, handleSagaErrors(handleCreateNewDevice));
  yield takeLatest(ActionTypes.RequestHistory, handleSagaErrors(handleRequestHistory));
  yield takeLatest(ActionTypes.Reassign, handleSagaErrors(handleReassign));
  yield takeLatest(ActionTypes.UpdateDevice, handleSagaErrors(handleUpdateDevice));
  yield takeLatest(ActionTypes.GetDeviceDetails, handleSagaErrors(handleGetDeviceDetails));
  yield takeLatest(ActionTypes.Assign, handleSagaErrors(handleAssign));
  yield takeLatest(FormActionTypes.AfterChangeFormField, handleSagaErrors(handleAfterChangeFormField));
}
