import { ActionTypes, FORM_TYPES } from './types';

export const initForm = (formName, fields, model, mode = FORM_TYPES.DEFAULT) => ({
  type: ActionTypes.InitForm,
  payload: { formName, fields, model, mode }
});

export const registerForm = (formName, formObject, formModel, formState, initialState) => ({
  type: ActionTypes.RegisterForm,
  payload: { formName, formObject, formModel, formState, initialState }
});

export const updateFormState = (formName, formState) => ({
  type: ActionTypes.UpdateFormState,
  payload: { formName, formState }
});

export const updateForm = (formName, fields, model) => ({
  type: ActionTypes.UpdateForm,
  payload: {
    formName,
    fields,
    model
  }
});

export const destroyForm = (formName) => ({
  type: ActionTypes.DestroyForm,
  payload: { formName }
});

export const initFormField = (formName, fieldName) => ({
  type: ActionTypes.InitFormField,
  payload: { formName, fieldName }
});

export const changeFormField = (formName, fieldName, value) => ({
  type: ActionTypes.ChangeFormField,
  payload: { formName, fieldName, value }
});

export const afterChangeFormField = (formName, fieldName) => ({
  type: ActionTypes.AfterChangeFormField,
  payload: { formName, fieldName }
});

export const changeFormFieldDataSource = (formName, fieldName, dataSource) => ({
  type: ActionTypes.ChangeFormFieldDataSource,
  payload: { formName, fieldName, dataSource }
});

export const updateFormField = (formName, model, conditions) => ({
  type: ActionTypes.UpdateFormField,
  payload: { formName, model, conditions }
});

export const changeFormFieldDefiniton = (formName, fieldName, fieldDefinition) => ({
  type: ActionTypes.ChangeFormFieldDefiniton,
  payload: { formName, fieldName, fieldDefinition }
});

export const submitForm = (formName, callback) => ({
  type: ActionTypes.SubmitForm,
  payload: { formName, callback }
});

export const submitMultipleForms = (formNames, callback) => ({
  type: ActionTypes.SubmitMultipleForms,
  payload: { formNames, callback }
});

export const formSubmissionFailed = () => ({
  type: ActionTypes.FormSubmissionFailed
});

export const touchForms = (formName) => ({
  type: ActionTypes.TouchForms,
  payload: { formName }
});
