import { Cookies } from 'react-cookie';
import { LANGUAGE_COOKIE_NAME } from '../constants/cookie-names';

export const getLangId = () => {
  const lang = getLangFromCookies();
  return lang && lang.id ? lang.id : 3;
};

export const getLangCode = () => {
  const lang = getLangFromCookies();
  return lang && lang.lang ? lang.lang : 'EN';
};

const getLangFromCookies = () => {
  const cookies = new Cookies();
  const lang = cookies.get(LANGUAGE_COOKIE_NAME);
  return lang;
};

export const setNewLanguageCookie = (language) => {
  const cookies = new Cookies();
  cookies.set(LANGUAGE_COOKIE_NAME, {
    id: language.value,
    lang: language.code
  });
}
