import { ActionTypes } from './types';

export const requestMyGeneralDetails = () => ({
  type: ActionTypes.RequestMyGeneralDetails
});

export const requestMyGeneralDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestMyGeneralDetailsSuccess,
  payload
});

export const updateMyGeneralDetails = () => ({
  type: ActionTypes.UpdateMyGeneralDetails
});

export const requestMyAvatar = () => ({
  type: ActionTypes.RequestMyAvatar
});

export const requestMyAvatarSuccess = (payload) => ({
  type: ActionTypes.RequestMyAvatarSuccess,
  payload
});

export const uploadNewAvatar = (newAvatarFile) => ({
  type: ActionTypes.UploadNewAvatar,
  newAvatarFile
});

export const updateMyPassword = () => ({
  type: ActionTypes.UpdateMyPassword
});
