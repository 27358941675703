import { combineReducers } from 'redux';
import App from '../layouts/reducer';
import Auth from '../pages/authentication/reducer';
import Users from '../pages/administration/user-management/users/reducer';
import Roles from '../pages/administration/user-management/roles/reducer';
import Nomenclatures from '../pages/administration/nomenclatures/reducer';
import UserProfile from '../pages/user/reducer';
import Clients from '../pages/clients/reducer';
import Form from '../common/form/reducer';
import Devices from '../pages/administration/devices/reducer';
import Dashboard from '../pages/dashboard/reducer';
import Wearables from '../pages/administration/wearables/reducer';

export default combineReducers({
  App,
  Auth,
  Users,
  Roles,
  UserProfile,
  Nomenclatures,
  Clients,
  Form,
  Devices,
  Dashboard,
  Wearables
});
