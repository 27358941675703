export const ActionTypes = {
  RequestUsers: 'move/pages/user-management/users/RequestUsers',
  RequestUsersSuccess: 'move/pages/user-management/users/RequestUsersSuccess',
  RequestUserAvatar: 'move/pages/user-management/users/RequestUserAvatar',
  RequestUserAvatarSuccess: 'move/pages/user-management/users/RequestUserAvatarSuccess',
  RequestUserGeneralDetails: 'move/pages/user-management/users/RequestUserGeneralDetails',
  RequestUserGeneralDetailsSuccess: 'move/pages/user-management/users/RequestUserGeneralDetalsSuccess',
  CleanUserProfile: 'move/pages/user-management/users/CleanUserProfile',
  UploadNewAvatar: 'move/pages/user-management/users/UploadNewAvatar',
  UpdateUserGeneralDetails: 'move/pages/user-management/users/UpdateUserGeneralDetails',
  RequestUserAdministrationSettings: 'move/pages/user-management/users/RequestUserAdministrationSettings',
  RequestUserAdministrationSettingsSuccess: 'move/pages/user-management/users/RequestUserAdministrationSettingsSuccess',
  UpdateUserAdministrationSettings: 'move/pages/user-management/users/UpdateUserAdministrationSettings',
  CreateNewUser: 'move/pages/user-management/users/CreateNewUser',
  RequestUserOptions: 'move/pages/user-management/users/RequestUserOptions',
  RequestUserOptionsSuccess: 'move/pages/user-management/users/RequestUserOptionsSuccess',
  ExportUsers: 'move/pages/user-management/users/ExportUsers',
  ImportUsers: 'move/pages/user-management/users/ImportUsers',
  DownloadUserInvitationTemplate: 'move/pages/user-management/users/DownloadUserInvitationTemplate',
  InviteUsersByTemplate: 'move/pages/user-management/users/InviteUsersByTemplate',
  SetExistingUsersEmails: 'move/pages/user-management/users/SetExistingUsersEmails',
  ResendUserInvitations: 'move/pages/user-management/users/ResendUserInvitations'
};

export const addNewUserForm = 'addNewUserForm';
export const userGeneralDetailsForm = 'userGeneralDetailsForm';
export const userAdministrationSettingsForm = 'userAdministrationSettingsForm';

export const CLIENT_ID_FIELD = 'clientId';
export const CLIENT_LOCATION_ID_FIELD = 'clientLocationId';
export const USER_TYPE_FIELD = 'userType';
export const USER_ROLE_FIELD = 'userRole';
export const LOCATION_ID_FIELD = 'locationId';
export const WEARABLE_FIELD = 'wearable';
export const COMPANY_POSITION_FIELD = 'companyPosition';

export const GLOBAL_ADMIN_OPTION = {
  value: 'GlobalAdmin',
  label: 'Global Admin',
  stringValue: 'GlobalAdmin'
};

export const COMPANY_ADMIN_OPTION = {
  value: 'CompanyAdmin',
  label: 'Company Admin',
  stringValue: 'CompanyAdmin'
};

export const ADMIN_ROLES_LIST = [{
  value: 'GlobalAdmin',
  label: 'Global Admin',
  stringValue: 'GlobalAdmin'
}, {
  value: 'CompanyAdmin',
  label: 'Company Admin',
  stringValue: 'CompanyAdmin'
}];

export const USER_ROLES_LIST = [{
  value: 'ReportingUser',
  label: 'Reporting User',
  stringValue: 'ReportingUser'
}, {
  value: 'RegularUser',
  label: 'Regular User',
  stringValue: 'RegularUser'
}];
