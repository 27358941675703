import { all } from 'redux-saga/effects';
import initSaga from '../layouts/saga';
import authSaga from '../pages/authentication/saga';
import usersSaga from '../pages/administration/user-management/users/saga';
import rolesSaga from '../pages/administration/user-management/roles/saga';
import nomenclaturesSaga from '../pages/administration/nomenclatures/saga';
import userProfileSaga from '../pages/user/saga';
import clientsSaga from '../pages/clients/saga';
import formSaga from '../common/form/saga';
import devicesSaga from '../pages/administration/devices/saga';
import dashboardSaga from '../pages/dashboard/saga';
import wearablesSaga from '../pages/administration/wearables/saga';

export default function* rootSaga(getState) {
  yield all([
    initSaga(),
    authSaga(),
    usersSaga(),
    rolesSaga(),
    userProfileSaga(),
    nomenclaturesSaga(),
    clientsSaga(),
    formSaga(),
    devicesSaga(),
    dashboardSaga(),
    wearablesSaga(),
  ]);
}
