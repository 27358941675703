import { ActionTypes  } from './types';

export const requestDevices = (pageParameters) => ({
  type: ActionTypes.RequestDevices,
  pageParameters
});

export const requestDevicesSuccess = (payload) => ({
  type: ActionTypes.RequestDevicesSuccess,
  payload
});

export const createNewDevice = () => ({
  type: ActionTypes.CreateNewDevice
});

export const updateDevice = (id, callback) => ({
  type: ActionTypes.UpdateDevice,
  id,
  callback
});

export const reassign = (callback, id) => ({
  type: ActionTypes.Reassign,
  id,
  callback
});

export const assign = (callback, id) => ({
  type: ActionTypes.Assign,
  id,
  callback
});

export const requestHistory = (id, pageParameters) => ({
  type: ActionTypes.RequestHistory,
  id,
  pageParameters
});

export const requestHistorySuccess = (payload) => ({
  type: ActionTypes.RequestHistorySuccess,
  payload
});

export const getDeviceDetails = (id) => ({
  type: ActionTypes.GetDeviceDetails,
  id
});

export const requestGetDeviceDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestGetDeviceDetailsSuccess,
  payload
});


