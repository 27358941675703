import { ActionTypes  } from './types';

export const requestUsers = (pageParameters) => ({
  type: ActionTypes.RequestUsers,
  pageParameters
});

export const requestUsersSuccess = (payload) => ({
  type: ActionTypes.RequestUsersSuccess,
  payload
});

export const requestUserAvatar = (userId) => ({
  type: ActionTypes.RequestUserAvatar,
  userId
});

export const requestUserAvatarSuccess = (payload) => ({
  type: ActionTypes.RequestUserAvatarSuccess,
  payload
});

export const requestUserGeneralDetails = (userId) => ({
  type: ActionTypes.RequestUserGeneralDetails,
  userId
});

export const requestUserGeneralDetailsSuccess = (payload) => ({
  type: ActionTypes.RequestUserGeneralDetailsSuccess,
  payload
});

export const cleanUserProfile = () => ({
  type: ActionTypes.CleanUserProfile
});

export const uploadNewAvatar = (userId, newAvatarFile) => ({
  type: ActionTypes.UploadNewAvatar,
  userId,
  newAvatarFile
});

export const updateUserGeneralDetails = (userId) => ({
  type: ActionTypes.UpdateUserGeneralDetails,
  userId
});

export const requestUserAdministrationSettings = (userId) => ({
  type: ActionTypes.RequestUserAdministrationSettings,
  userId
});

export const requestUserAdministrationSettingsSuccess = (payload) => ({
  type: ActionTypes.RequestUserAdministrationSettingsSuccess,
  payload
});

export const updateUserAdministrationSettings = (userId, administrationSettings) => ({
  type: ActionTypes.UpdateUserAdministrationSettings,
  userId,
  administrationSettings
});

export const createNewUser = () => ({
  type: ActionTypes.CreateNewUser
});

export const requestUserOptions = () => ({
  type: ActionTypes.RequestUserOptions,
});

export const requestUserOptionsSuccess = (payload) => ({
  type: ActionTypes.RequestUserOptionsSuccess,
  payload
});

export const exportUsers = () => ({
  type: ActionTypes.ExportUsers,
});

export const importUsers = (file) => ({
  type: ActionTypes.ImportUsers,
  file
});

export const downloadUserInvitationTemplate = () => ({
  type: ActionTypes.DownloadUserInvitationTemplate,
});

export const inviteUsersByTemplate = (file) => ({
  type: ActionTypes.InviteUsersByTemplate,
  file
});

export const setExistingUsersEmails = (payload = []) => ({
  type: ActionTypes.SetExistingUsersEmails,
  payload
});

export const resendUserInvitations = (emails, callback) => ({
  type: ActionTypes.ResendUserInvitations,
  emails,
  callback
});
