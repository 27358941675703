export const ActionTypes = {
  InitForm: '@@form/cxg/common/INIT_FORM',
  RegisterForm: '@@form/cxg/common/REGISTER_FORM',
  UpdateForm: '@@form/cxg/common/UPDATE_FORM',
  UpdateFormState: '@@form/cxg/common/UPDATE_FORM_STATE',
  DestroyForm: '@@form/cxg/common/DESTROY_FORM',
  InitFormField: '@@form/cxg/common/INIT_FORM_FIELD',
  ChangeFormField: '@@form/cxg/common/CHANGE_FORM_FIELD',
  AfterChangeFormField: '@@form/cxg/common/AFTER_CHANGE_FORM_FIELD',
  UpdateFormField: '@@form/cxg/common/UPDATE_FORM_FIELD',
  SubmitForm: '@@form/cxg/common/SUBMIT_FORM',
  SubmitMultipleForms: '@@form/cxg/common/SubmitMultipleForms',
  TouchForms: '@@form/cxg/common/TOUCH_FORMS',
  ChangeFormFieldDefiniton: '@@form/cxg/common/CHANGE_FORM_FIELD_DEFINITION',
  ChangeFormFieldDataSource: '@@form/cxg/common/CHANGE_FORM_FIELD_DATA_SOURCE',
};

export const FIELD_TYPES = {
  CHECKBOX: 'checkbox',
  NUMERIC: 'numeric',
  RADIO_GROUP: 'radio',
  CHECKBOX_GROUP: 'checkbox_group',
  CUSTOM: 'custom',
  SECTION: 'section',
  DROPDOWN: 'dropdown',
  FORM_GROUP: 'formGroup',
  GRID: 'grid',
  TEXT: 'text',
  TEXTAREA: 'textarea',
  DATE_PICKER: 'date',
  MULTI_SELECT: 'multiselect',
  HIDDEN: 'hidden',
  EMAIL: 'email',
  LOGO: 'logo',
  TOGGLE_SWITCH: 'toggleSwitch',
  PASSWORD: 'password'
};

export const defaultFormState = {
  pristine: true,
  errors: false,
  submitted: false,
  mode: 'default'
};

export const FORM_TYPES = {
  DEFAULT: 'default',
  PREVIEW: 'preview'
};
