export const ActionTypes = {
  RequestMyGeneralDetails: 'move/pages/user-profile/RequestMyGeneralDetails',
  RequestMyGeneralDetailsSuccess: 'move/pages/user-profile/RequestMyGeneralDetailsSuccess',
  UpdateMyGeneralDetails: 'move/pages/user-profile/UpdateMyGeneralDetails',
  RequestMyAvatar: 'move/pages/user-profile/RequestMyAvatar',
  RequestMyAvatarSuccess: 'move/pages/user-profile/RequestMyAvatarSuccess',
  UploadNewAvatar: 'move/pages/user-profile/UploadNewAvatar',
  CleanMyProfile: 'move/pages/user-profile/CleanMyProfile',
  UpdateMyPassword: 'move/pages/user-profile/UpdateMyPassword'
};

export const userProfileSettingsForm = 'userProfileSettingsForm';
export const userPasswordChangeForm = 'userPasswordChangeForm';
