import { ActionTypes } from './types.js';

export const initApp = () => ({
  type: ActionTypes.InitApp
});

export const initAppSuccess = (payload) => ({
  type: ActionTypes.InitAppSuccess,
  payload
});

export const requestNomencatures = () => ({
  type: ActionTypes.RequestNomencatures,
});

export const requestNomencaturesSuccess = (payload) => ({
  type: ActionTypes.RequestNomencaturesSuccess,
  payload
});

export const requestDashboardClients = () => ({
  type: ActionTypes.RequestDashboardClients
});

export const requestDashboardClientsSuccess = (payload) => ({
  type: ActionTypes.RequestDashboardClientsSuccess,
  payload
});

export const saveUserLanguage = (payload) => ({
  type: ActionTypes.SaveUserLanguage,
  payload
});
